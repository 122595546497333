import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ServicePage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="serv_page page">
      <div className="banner">
        <div className="title_page">{t('our services')}</div>
      </div>
      <div className="container">
        <div className="row align-items-center" id="import">
          <div className="col-lg-6">
            <div className="serv_name">{t("import")}</div>
            <div className="desc">
              {t(
                "BIOMAB imports the required and unique products through a series of agreements with international medical and pharmaceutical companies and factories to Turkey and the Middle East while ensuring product quality and fast delivery to our various customers."
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/serv1.png")}
              alt="BIOMAB"
              width="100%"
            />
          </div>
        </div>
        <div className="row  align-items-center" id="export">
          <div className="col-lg-6">
            <div className="serv_name">{t("export")}</div>
            <div className="desc">
              {t(
                "Biomab exports medical products and supplies from Turkey and countries in which we have partnerships, with high level of guarantees and fast delivery process to our different clients such as health clinics, private hospitals, government institutions, international tenders, international NGOs and cosmetic clinics in more than 25 countries. This includes the best conditions for long-distance cold chain medicine delivery."
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/serv2.jpg")}
              alt="BIOMAB"
              width="100%"
            />
          </div>
        </div>
        <div className="row align-items-center" id="Tenders">
          <div className="col-lg-6">
            <div className="serv_name">{t("Tenders")}</div>
            <div className="desc">
              {t(
                "BIOMAB works in cooperation with the health ministries of many countries on the shortage list and also with a lot of non-governmental organizations in different parts of the world. We strive to supply all kinds of products by meeting shipping deadlines and at competitive prices."
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/serv3.jpg")}
              alt="BIOMAB"
              width="100%"
            />
          </div>
        </div>
        <div className="row align-items-center" id="trading">
          <div className="col-lg-6">
            <div className="serv_name">{t("trading")}</div>
            <div className="desc">
              {t(
                "BIOMAB works with many healthcare providers, international warehouses and suppliers in many countries to provide customers with the necessary products, improve trade opportunities, and obtain high quality products at reasonable prices."
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/serv4.png")}
              alt="BIOMAB"
              width="100%"
            />
          </div>
        </div>
        <div className="row  align-items-center" id="Health_tourism">
          <div className="col-lg-6">
            <div className="serv_name">{t("Health tourism")}</div>
            <div className="desc">
              {t(
                "Through coordination with many advanced and reliable hospitals and health centers, we provide health and beauty services within high quality standards and ensure the follow-up process."
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/serv5.png")}
              alt="BIOMAB"
              width="100%"
            />
          </div>
        </div>
        <div className="row align-items-center" id="medical_production">
          <div className="col-lg-6">
            <div className="serv_name">{t("medical production")}</div>
            <div className="desc">
              {t(
                "BIOMAB provides the necessary services to carry out private label manufacturing of cosmetics products and medical supplies through modern technologies within specialized factories."
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/serv6.jpg")}
              alt="BIOMAB"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicePage;
