import { Link } from "react-router-dom";
import SideBar from "./SideBar";
import { useTranslation } from "react-i18next";
import LanguageDropDown from "./LanguageDropDown";

const Header = () => {
  const { t } = useTranslation();

  return (
    <header>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3 col-sm-6 col-6">
            <Link to="/medical">
              <img
                src={require("../assets/img/logo.png")}
                alt="logo"
                className="logo"
              />
            </Link>
          </div>
          <div className="col-md-9 col-md-6 col-6">
            <ul className="list-unstyled align-items-center d-lg-flex d-md-none d-sm-none d-none justify-content-evenly m-0">
              <li>
                <Link to="/medical">{t("home")}</Link>
              </li>
              <li>
                <Link to="/about-us">{t("about us")}</Link>
              </li>
              <li>
                <Link to="/our-services">{t("services")}</Link>
              </li>
              <li>
                <Link to="/our-products">{t("products")}</Link>
              </li>
              <li>
                <Link to="/contact-us">{t("contact us")}</Link>
              </li>
              <li>
                <LanguageDropDown />
              </li>
            </ul>
            <SideBar />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
