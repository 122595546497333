import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settingsCategory = {
    dots: false,
    infinite: true,
    margin: 20,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
     
      <Slider {...settings} className="main-slider">
        <div className="item item1">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-12">
                <h2>{t("welcome to biomab")}</h2>
                <h1>{t("High-quality Products")} </h1>
                <p>
                  {t(
                    "Have confidence, our products are approved by the Turkish Ministry of Health and adhere to the highest international standards."
                  )}
                </p>
                <div className="btn-group">
                  <HashLink to="#our_services">{t("our services")}</HashLink>
                  <HashLink to="#our_products">{t("our products")}</HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item item2">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-lg-12">
                <h2>{t("welcome to biomab")}</h2>
                <h1>{t("High-quality Products")} </h1>
                <p>
                  {t(
                    "Have confidence, our products are approved by the Turkish Ministry of Health and adhere to the highest international standards."
                  )}
                </p>
                <div className="btn-group">
                  <HashLink to="#our_services">{t("our services")}</HashLink>
                  <HashLink to="#our_products">{t("our products")}</HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
      <div className="about-section" id="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="sub-title">{t("about us")}</div>
              <div className="title">
                {t(
                  "BIOMAB is a premier provider of pharmaceutical and medical products in Turkey,"
                )}
              </div>
              <p className="desc mb-5">
                {t(
                  "with a reputation for delivering top-quality products and exceptional customer service. BIOMAB Company was established in 2019 under our established group in 2011, the company was founded with the vision of becoming a leading player in the medical and pharmaceutical industry and introducing high-grade products to the Turkish market."
                )}
              </p>
              <Link to="/about-us">{t("read more")}</Link>
            </div>
            <div className="col-lg-5">
              <img
                src={require("../../assets/img/Screenshot (379).png")}
                width="100%"
                alt="biomab"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="serv-section" id="our_services">
        <img
          src={require("../../assets/img/left-shape.png")}
          className="left-shape"
          alt="BIOMAB"
        />
        <div className="container">
          <div className="sub-title text-center text-white">
            {t("our services")}
          </div>
          <div className="title text-center text-white mb-5">
            {t("Explore Our Main Service")}
          </div>
          <div className="row pt-4">
            <div className="col-lg-4 col-md-6 mb-5">
              <div className="box">
                <div className="icon">
                  <img
                    src={require("../../assets/img/import.png")}
                    alt="BIOMAB"
                  />
                </div>
                <div className="serv_name">{t("import")}</div>
                <p className="desc">
                  {t(
                    "Biomab imports the required and unique products through a series of agreements with international medical and pharmaceutical companies..."
                  )}
                </p>
                <HashLink to="/our-services#import">
                  {t("read more")} +
                </HashLink>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-5">
              <div className="box">
                <div className="icon">
                  <img
                    src={require("../../assets/img/logistics.png")}
                    alt=" biomab"
                  />
                </div>
                <div className="serv_name">{t("export")}</div>
                <p className="desc">
                  {t(
                    "Biomab exports medical products and supplies from Turkey and countries in which we have partnerships..."
                  )}
                </p>
                <HashLink to="/our-services#export">
                  {t("read more")} +
                </HashLink>
              </div>
            </div>
            <div className="col-lg-4 col-md-6  mb-5">
              <div className="box">
                <div className="icon">
                  <img
                    src={require("../../assets/img/tender.png")}
                    alt="biomab"
                  />
                </div>
                <div className="serv_name">{t("Tenders")}</div>
                <p className="desc">
                  {t(
                    "BIOMAB works in cooperation with the health ministries of many countries on the shortage list and also with a lot of non-governmental organizations.."
                  )}
                </p>
                <HashLink to="/our-services#Tenders">
                  {t("read more")} +
                </HashLink>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-5">
              <div className="box">
                <div className="icon">
                  <img
                    src={require("../../assets/img/trade.png")}
                    alt="biomab"
                  />
                </div>
                <div className="serv_name">{t("trading")}</div>
                <p className="desc">
                  {t(
                    "BIOMAB works with many healthcare providers, international warehouses and suppliers in many countries.."
                  )}
                </p>
                <HashLink to="/our-services#trading">
                  {t("read more")} +
                </HashLink>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-5">
              <div className="box">
                <div className="icon">
                  <img
                    src={require("../../assets/img/heartbeat.png")}
                    alt="BIOMAB"
                  />
                </div>
                <div className="serv_name">{t("Health tourism")}</div>
                <p className="desc">
                  {t(
                    "Through coordination with many advanced and reliable hospitals and health centers, we provide health and beauty services within high quality standards.."
                  )}
                </p>
                <HashLink to="/our-services#Health_tourism">
                  {t("read more")} +
                </HashLink>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-5">
              <div className="box">
                <div className="icon">
                  <img
                    src={require("../../assets/img/production.png")}
                    alt="biomab"
                  />
                </div>
                <div className="serv_name">{t("medical production")}</div>
                <p className="desc">
                  {t(
                    "BIOMAB provides the necessary services to carry out private label manufacturing of cosmetics products and medical supplies.."
                  )}
                </p>
                <HashLink to="/our-services#medical_production">
                  {t("read more")} +
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="category-section" id="our_products">
        <div className="container">
          <div className="row pb-5">
            <div className="col-md-9">
              <div className="title text-uppercase ">{t("our products")}</div>
              <div className="desc ">
                {t(
                  "BIOMAB Pharmaceuticals Warehouse provides all kinds of Turkish and international medications. With our extensive range, fulfilling all your requirements has never been easier."
                )}
              </div>
            </div>
          </div>

          <Slider {...settingsCategory} className="cat_slider">
            <div className="item">
              <div className="box">
                <img
                  src={require("../../assets/img/product1.png")}
                  alt="BIOMAB"
                />
                <div className="serv_name">{t("Pharmaceuticals")}</div>
                <div className="desc">
                  {t(
                    "Prescription medicines, OTC medicines, Probiotics, Oncology, Dermatology, Biology and Blood products..."
                  )}
                </div>
                <HashLink to="/our-products#Pharmaceuticals">
                  {t("read more")} +
                </HashLink>
              </div>
            </div>

            <div className="item">
              <div className="box">
                <img
                  src={require("../../assets/img/product2.png")}
                  alt="BIOMAB"
                />
                <div className="serv_name">{t("equipments")}</div>
                <div className="desc">
                  {t(
                    "All types of local and international devices, tools, supplies and equipment necessary for hospitals..."
                  )}
                </div>
                <HashLink to="/our-products#equipments">
                  {t("read more")} +
                </HashLink>
              </div>
            </div>
            <div className="item">
              <div className="box">
                <img
                  src={require("../../assets/img/product3.png")}
                  alt="BIOMAB"
                />
                <div className="serv_name">{t("Medical Disposables")}</div>
                <div className="desc">
                  {t(
                    "Various types of local and international, sterile single and multi-use consumables products used in hospitals, nursing rooms..."
                  )}
                </div>
                <HashLink to="/our-products#Medical_Disposables">
                  {t("read more")} +
                </HashLink>
              </div>
            </div>
            <div className="item">
              <div className="box">
                <img
                  src={require("../../assets/img/product4.jpg")}
                  alt="BIOMAB"
                />
                <div className="serv_name">{t("Laboratory Supplies")}</div>
                <div className="desc">
                  {t(
                    "Various types of supplies, tubes, medical containers, reagents, kits, lab tests..."
                  )}
                </div>
                <HashLink to="/our-products#Laboratory_Supplies">
                  {t("read more")} +
                </HashLink>
              </div>
            </div>
            <div className="item">
              <div className="box">
                <img
                  src={require("../../assets/img/product5.webp")}
                  alt="BIOMAB"
                />
                <div className="serv_name">{t("Cosmetics")}</div>
                <div className="desc">
                  {t(
                    "The most effective and pure national and international branded products, which are used to increase and support people's quality of life..."
                  )}
                </div>
                <HashLink to="/our-products#Cosmetics">
                  {t("read more")} +
                </HashLink>
              </div>
            </div>
            <div className="item">
              <div className="box">
                <img
                  src={require("../../assets/img/product6.png")}
                  alt="BIOMAB"
                />
                <div className="serv_name">{t("Supplements")}</div>
                <div className="desc">
                  {t(
                    "Various types of Vitamins, Minerals, Natural and Vegan Dietary Supplements that are important for public health, to raise immunity..."
                  )}
                </div>
                <HashLink to="/our-products#Supplements">
                  {t("read more")} +
                </HashLink>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <div className="number_section">
        <div className="container">
          <div className="row row_num">
            <div className="col-lg-7 d-lg-block d-md-none d-sm-none d-none"></div>
            <div className="col-lg-5 col-md-12">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-sm-4 col-6 mb-5 text-center">
                  <div className="icon">
                    <img
                      src={require("../../assets/img/product.png")}
                      alt="biomab"
                    />
                  </div>
                  <div className="num">300</div>
                  <h4 className="fw-bold">{t("PRODUCTS")}</h4>
                </div>
                <div className="col-lg-4  col-sm-4  col-6 mb-5 text-center">
                  <div className="icon">
                    <img
                      src={require("../../assets/img/sales.png")}
                      alt="biomab"
                    />
                  </div>
                  <div className="num">8500</div>
                  <h4 className="fw-bold">{t("Sales Week")}</h4>
                </div>
                <div className="col-lg-4   col-sm-4 col-6 mb-5 text-center">
                  <div className="icon">
                    <img
                      src={require("../../assets/img/map.png")}
                      alt="biomab"
                    />
                  </div>
                  <div className="num">2124</div>
                  <h4 className="fw-bold">{t("Visits")}</h4>
                </div>
                <div className="col-lg-4  col-sm-4 col-6 mb-5 text-center">
                  <div className="icon">
                    <img
                      src={require("../../assets/img/coronavirus.png")}
                      alt="biomab"
                    />
                  </div>
                  <div className="num">25</div>
                  <h4 className="fw-bold">{t("COUNTRIES")}</h4>
                </div>

                <div className="col-lg-4  col-sm-4 col-6 mb-5 text-center">
                  <div className="icon">
                    <img
                      src={require("../../assets/img/office-building.png")}
                      alt="biomab"
                    />
                  </div>
                  <div className="num">3</div>
                  <h4 className="fw-bold">{t("Offices")}</h4>
                </div>
                <div className="col-lg-4  col-sm-4 col-6 mb-5 text-center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="logos my-5 py-5 text-center">
        <div className="container">
          <div className="title text-uppercase">{t("References")}</div>
          <p className="desc">
            {t(
              "BIOMAB HAS BRANCHES IN THE MIDDLE EAST AND IS AN APPROVED VENDOR FOR THE UNITED NATIONS (UN) AND VARIOUS NGOS, INCLUDING UNRWA AND UNHCR. OUR TRACK RECORD OF PARTICIPATING IN UN TENDERS AND FULFILLING COMMITMENTS EFFICIENTLY SPEAKS TO OUR COMMITMENT TO EXCELLENCE AND RELIABILITY."
            )}
          </p>
          <div className="row mt-5 justify-content-center">
            <div className="col-lg-2 col-sm-4 col-6 text-center">
              <img src={require("../../assets/img/unrwa.png")} alt="BIOMAB" />
            </div>
            <div className="col-lg-2 col-sm-4 col-6 text-center">
              <img src={require("../../assets/img/unchr.png")} alt="BIOMAB" />
            </div>
            <div className="col-lg-2 col-sm-4 col-6 text-center">
              <img src={require("../../assets/img/unicef.png")} alt="BIOMAB" />
            </div>
            <div className="col-lg-2 col-sm-4 col-6 text-center">
              <img src={require("../../assets/img/undp.png")} alt="BIOMAB" />
            </div>
            <div className="col-lg-2 col-sm-4 col-6 text-center">
              <img src={require("../../assets/img/unfpa.png")} alt="BIOMAB" />
            </div>
            <div className="col-lg-2 col-sm-4 col-6 text-center">
              <img
                src={require("../../assets/img/download.jfif")}
                alt="BIOMAB"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
