import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import "./assets/css/style.css";
import Header from "./component/Header";
import Footer from "./component/Footer";
import AboutPage from "./pages/about/AboutPage";
import ServicePage from "./pages/services/ServicePage";
import ContactPage from "./pages/contact/ContactPage";
import { useTranslation } from "react-i18next";
import ProductDetails from "./pages/products/ProductDetails";
import IntroBanner from "./pages/intro/IntroBanner";

function App() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.language === "en" ? "ltr" : "rtl";
  document.documentElement.lang = i18n.language;
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<IntroBanner />} path="/" />
          <Route element={<MainLayout />} path="/*" />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function MainLayout() {
  return (
    <>
      <Header />
      <Routes>
        <Route element={<Home />} path="/medical" />
        <Route element={<AboutPage />} path="/about-us" />
        <Route element={<ServicePage />} path="/our-services" />
        <Route element={<ProductDetails />} path="/our-products" />
        <Route element={<ContactPage />} path="/contact-us" />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
