import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../i18n";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <img
              src={require("../assets/img/logo.png")}
              alt="logo"
              className="logo"
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6">
            <h2>{t("links")}</h2>
            <ul className="list-unstyled">
              <li>
                <Link to="/medical">{t("home")}</Link>
              </li>
              <li>
                <Link to="/about-us">{t("about us")}</Link>
              </li>
              <li>
                <Link to="/our-services">{t("services")}</Link>
              </li>
              <li>
                <Link to="">{t("products")}</Link>
              </li>
              <li>
                <Link to="/contact-us">{t("contact us")}</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <h2>{t("contact info")}</h2>
            <ul className="list-unstyled contact-info">
              <li>
                {i18n.language === "en" ? (
                  <a href="tel:905011171112" className="link_en">
                    <i className="fal fa-phone-alt"></i>+90 501 117 1112
                  </a>
                ) : (
                  <a href="tel:905011171112" className="link_ar">
                    <i className="fal fa-phone-alt"></i> +90 501 117 1112
                  </a>
                )}
              </li>

              <li>
                <a href="mailto:info@biomab.net">
                  <i className="fal fa-envelope"></i> info@biomab.net
                </a>
              </li>
              <li>
                {i18n.language === "en" ? (
                  <a href="https://wa.me/905011171112" rel="_blank">
                    <i className="fab fa-whatsapp"></i>+90 501 117 1112
                  </a>
                ) : (
                  <a href="https://wa.me/905011171112" rel="_blank">
                    <i className="fab fa-whatsapp"></i> +90 501 117 1112
                  </a>
                )}
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <h2>{t("follow us")}</h2>
            <div className="social">
              <Link to="">
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link to="">
                <i className="fab fa-instagram"></i>
              </Link>
              <Link to="">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link to="">
                <i className="fab fa-linkedin-in"></i>
              </Link>
              <Link to="">
                <i className="fab fa-behance"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <a href="mell:info@FutureCode-co.com">
        <div className="bottom-footer">{t("Copyright © 2024 FutureCode.")}</div>
      </a>
      <img
        src={require("../assets/img/Screenshot (400).png")}
        alt="logo"
        className="left_shape d-lg-block d-md-block d-sm-none d-none"
      />
      <img
        src={require("../assets/img/Screenshot (394).png")}
        alt="logo"
        className="right_shape d-lg-none d-md-none d-sm-block d-block"
      />
    </footer>
  );
};

export default Footer;
