import React, { useContext } from "react";
import LangContext from "../context/LangContext";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import i18n from "../i18n";

const LanguageDropDown = () => {
  const langsContext = useContext(LangContext);
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  return (
    <FormControl
      variant="filled"
      sx={{ minWidth: 120 }}
      size="small"
      className="lang-select"
    >
      <InputLabel id="demo-simple-select-filled-label">Language</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={langsContext.langState}
        onChange={langsContext.set_lang}
      >
        {langsContext.langs.map((item) => (
          <MenuItem value={item.code} key={item.code} onClick={() => {
            changeLanguage(langsContext.langState);
          }}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageDropDown;
