import { Link } from "react-router-dom";
import ContactForm from "./ContactForm";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ContactPage = () => {
  const { t } = useTranslation();
  useEffect(
    () => {
        window.scrollTo(0, 0);
    },
    [])
  return (
    <div className="contact_page page">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.4226747477837!2d28.644180455590195!3d41.016007832512486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b55f0931b5a16b%3A0x5cca14f076215cf1!2sAkros%20Istanbul!5e0!3m2!1sar!2s!4v1697566687764!5m2!1sar!2s"
        width="100%"
        height="600"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <div className="container mb-5">
        <div className="box">
          <div className="row">
            <div className="col-lg-7">
              <div className="content">
                <div className="title">{t('Get In Touch !!')}</div>
                <div className="desc mb-4">
                  {t('We strive to provide the best service to our customers and ensure your satisfaction.')}
                </div>
                <ContactForm />
              </div>
            </div>
            <div className="col-lg-5 ">
              <div className="col-right">
              <div className="title text-white">{t('DISTRIBUTION REGIONS')}</div>
              <h3>MIDDLE EAST</h3>
              <h3>AFRICA</h3>
              <h3>TURKEY</h3>
              <h3>NEIGHBOURING COUNTRIES</h3>
              <ul className="list-unstyled">
                <li>
                  <Link to="">
                    <i className="fal fa-phone-alt"></i>+90 501 117 1112
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <i className="fal fa-envelope"></i> info@biomab.net
                  </Link>
                </li>
                <li>
                  <i class="fal fa-map-marker-alt"></i> BARBAROS HAYRETTİN PAŞA
                  Mah. 1995 Sok. AKROS İSTANBUL No: 3-1/ Ofis No: 224,
                  Cumhuriyet, 34522 Esenyurt/İstanbul, Turkey
                </li>
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
