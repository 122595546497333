import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const ContactForm = () => {
  const { t } = useTranslation();
  return (
    <>
      <TextField id="filled-basic" label={t("Name")} variant="filled" fullWidth />
      <TextField id="filled-basic" label={t("Email")} variant="filled" fullWidth />
      <TextField
        id="filled-basic"
        label={t("Phone Number")}
        type="number"
        variant="filled"
        fullWidth
      />
      <TextField
        id="filled-multiline-static"
        label={t("Message")}
        fullWidth
        multiline
        rows={4}
        variant="filled"
      />
      <button className="btn btn-submit">{t('submit')}</button>

    </>
  );
};

export default ContactForm;
