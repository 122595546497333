import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ProductDetails = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="serv_page page">
      <div className="banner">
        <div className="title_page">{t("our products")}</div>
      </div>
      <div className="container">
        <div className="row align-items-center" id="Pharmaceuticals">
          <div className="col-lg-6">
            <div className="serv_name">{t("Pharmaceuticals")}</div>
            <div className="desc">
              {t(
                "Prescription medicines, OTC medicines, Probiotics, Oncology, Dermatology, Biology and Blood products.  Anti-infective and vaccines products. Medications for chronic diseases in various pharmaceutical forms and different storage methods."
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/product1.png")}
              alt="BIOMAB"
              width="100%"
            />
          </div>
        </div>
        <div className="row  align-items-center" id="equipments">
          <div className="col-lg-6">
            <div className="serv_name">{t("equipments")}</div>
            <div className="desc">
              {t(
                "All types of local and international devices, tools, supplies and equipment necessary for hospitals and surgical operating rooms, as well as supplies for biological and chemical laboratories, clinics and health centers."
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/product2.png")}
              alt="BIOMAB"
              width="100%"
            />
          </div>
        </div>
        <div className="row align-items-center" id="Medical_Disposables">
          <div className="col-lg-6">
            <div className="serv_name">{t("Medical Disposables")}</div>
            <div className="desc">
              {t(
                "Various types of local and international, sterile single and multi-use consumables products used in hospitals, nursing rooms, medical laboratories and medical clinics, including international quality certificates."
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/product3.png")}
              alt="BIOMAB"
              width="100%"
            />
          </div>
        </div>
        <div className="row align-items-center" id="Laboratory_Supplies">
          <div className="col-lg-6">
            <div className="serv_name">{t("Laboratory Supplies")}</div>
            <div className="desc">
              {t(
                "Various types of supplies, tubes, medical containers, reagents, kits, lab tests and chemical solutions necessary for biological, biochemical and medical research laboratories."
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/product4.jpg")}
              alt="BIOMAB"
              width="100%"
            />
          </div>
        </div>
        <div className="row  align-items-center" id="Cosmetics">
          <div className="col-lg-6">
            <div className="serv_name">{t("Cosmetics")}</div>
            <div className="desc">
              {t(
                "The most effective and pure national and international branded products, which are used to increase and support people's quality of life. Natural and vegan cosmetic products that achieve maintenance and improvement of skin and hair care as well as personal health and beauty."
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/product5.webp")}
              alt="BIOMAB"
              width="100%"
            />
          </div>
        </div>
        <div className="row align-items-center" id="Supplements">
          <div className="col-lg-6">
            <div className="serv_name">{t("Supplements")}</div>
            <div className="desc">
              {t(
                "Various types of Vitamins, Minerals, Natural and Vegan Dietary Supplements that are important for public health, to raise immunity, against some biological problems and to treat nutritional deficiencies which available in various pharmaceutical forms, which facilitates their use and maintenance"
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/product6.png")}
              alt="BIOMAB"
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
