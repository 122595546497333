import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LangContextProvider } from './context/LangContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <LangContextProvider>
    <App />
    </LangContextProvider>
  </React.StrictMode>
);

reportWebVitals();
