import { createContext, useState } from "react";
import i18n from "../i18n";
const LangContext = createContext({
  langs: [],
  langState: "",
  set_lang: () => {},
});
export function LangContextProvider(props) {
  const langArray = [
    {
      code: "en",
      name: "English",
    },
    {
      code: "ar",
      name: "Arabic",
    },
    {
      code: "tr",
      name: "Turky",
    },
  ];
  const [lang, setLang] = useState("en");
  const langs = langArray;
  const handleChange = (event) => {
    setLang(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const context = {
    langs: langs,
    langState: lang,
    set_lang: handleChange,
  };

  return (
    <LangContext.Provider value={context}>
      {props.children}
    </LangContext.Provider>
  );
}

export default LangContext;
