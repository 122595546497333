import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { Link } from "react-router-dom";


const SideBar = () => {
  const { t  } = useTranslation();
  
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);

}
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  return (
    <>
      <div className="burger " onClick={showSidebar}>
        <i className="fas fa-bars"></i>
      </div>
      <div className={sidebar ? "nav-menu active" : "nav-menu"}>
        <div onClick={showSidebar} className="icon-close">
          <i className="fas fa-times"></i>
        </div>
        <ul className="list-unstyled text-center ">
        <li>
                <Link to="/medical" onClick={showSidebar}>{t("home")}</Link>
              </li>
              <li>
                <Link to="/about-us" onClick={showSidebar}>{t('about us')}</Link>
                
              </li> 
              <li> 
              <Link to="/our-services" onClick={showSidebar}>{t('services')}</Link>
                
              </li>
              <li>
              <Link to="/our-products" onClick={showSidebar}>{t('products')}</Link>
                
              </li>
              <li>
              <Link to="/contact-us" onClick={showSidebar}>{t('contact us')}</Link>
              
              </li>
              <li onClick={showSidebar}>
                <button
                  type="button"
                  className="bg-transparent border-0 "
                
                  onClick={() => {
                    i18n.language === "en"
                      ? changeLanguage("ar")
                      : changeLanguage("en");
                  }}
                >
                  {i18n.language === "en" ? "Arabic" : "English"}
                </button>
              </li>
        </ul>
      </div>
    </>
  );
};

export default SideBar;
