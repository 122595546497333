import videoIntro from "../../assets/img/intro/intro.mp4";
import medicalLogo from "../../assets/img/intro/biomabmedical.png";
import supplyLogo from "../../assets/img/intro/biomabsupply.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const IntroBanner = () => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="intro_page position-relative">
      <video autoPlay muted loop>
        <source src={videoIntro} type="video/mp4" />
      </video>
      <div className={`content ${showContent ? "delayed-fade-in" : "hidden"}`}>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-sm-6 col-6 text-start">
              <Link to="/medical">
                <img src={medicalLogo} alt="biomab" />
              </Link>
            </div>
            <div className="col-sm-6 col-6 text-end">
              <Link to="https://supply.biomab.net/" target="_blank">
                <img src={supplyLogo} alt="biomab" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroBanner;
