import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const { t } = useTranslation();
  useEffect(
    () => {
        window.scrollTo(0, 0);
    },
    [])
  return (
    <div className="about_page page">
      <div className="banner">
        <div className="title_page">{t('about us')}</div>
      </div>
      <div className="container ">
        <div className="mb-5">
          <div className="title">{t('About BIOMAB Company')} </div>
          <div className="desc">
            {t('BIOMAB is a premier provider of pharmaceutical and medical products in Turkey, with a reputation for delivering top-quality products and exceptional customer service. BIOMAB Company was established in 2019 under our established group in 2011, the company was founded with the vision of becoming a leading player in the medical and pharmaceutical industry and introducing high-grade products to the Turkish market.')}
            <br></br> <br></br>
            {t('As a full-service importing, exporting, and trading company, BIOMAB has cultivated strong relationships with suppliers and customers from around the globe. We are always seeking new opportunities to expand our reach and broaden our offerings. In addition to serving the Turkish market, BIOMAB has branches in the Middle East and is an approved vendor for the United Nations (UN) and various NGOs, including UNRWA and UNHCR. Our track record of participating in UN tenders and fulfilling commitments efficiently speaks to our commitment to excellence and reliability.')}
            <br></br>
            <br></br>
            {t('To further bolster our position in the market, BIOMAB is actively establishing partnerships with diverse companies and pharmacies throughout the country.')}
            {t('These partnerships allow us to tap into our partners expertise in delivering high-quality products, providing superior service, and meeting customer needs and expectations.')}
          </div>
        </div>
        <div className="mb-5">
          <div className="title">{t('Organizational Approach')}</div>
          <div className="desc">
            {t('At BIOMAB, our mission is to become a leading provider of high-quality pharmaceutical and medical products in Turkey, the Middle East, and Africa and to establish ourselves as a major contributor to the medical and pharmaceutical industry. To achieve this, we have developed a comprehensive organizational approach that focuses on the following key areas:')}
          </div>
          <div className="row list_about">
            <div className="col-lg-6 mb-5 position-relative">
              <div className="content">
                <h1>01</h1>
                <h2 className=" fw-bold">{t('Premium Product Development')}</h2>
                <p className="desc">
                  {t('OUR COMMITMENT TO EXCELLENCE DRIVES US TO ANALYZE MARKETS, UNDERSTAND CUSTOMER NEEDS, AND SELECT CUTTING-EDGE PRODUCTS THROUGH PARTNERSHIPS WITH GLOBAL PHARMACEUTICAL LEADERS.')}
                </p>
              </div>
            </div>
            <div className="col-lg-6 mb-5 position-relative">
              <div className="content">
                <h1>02</h1>
                <h2 className=" fw-bold">{t('Professional Services')}</h2>
                <p className="desc">
                  {t('OUR SERVICE QUALITY MATCHES OUR PREMIUM PRODUCTS. RIGOROUS TRAINING FOR STAFF AND TRINGENT QUALITY STANDARDS ENSURE EXCEPTIONAL CUSTOMER EXPERIENCES.')}
                </p>
              </div>
            </div>
            <div className="col-lg-6 mb-5 position-relative">
              <div className="content">
                <h1>03</h1>
                <h2 className=" fw-bold">{t('Service Expansion')}</h2>
                <p className="desc">
                  {t('WE’RE EXPANDING BY PARTNERING WITH LOCAL EXPERTISE, ENTERING NEW MARKETS IN THE MIDDLE EAST, AND COLLABORATING WITH DRUGSTORES FOR WIDER PHARMA INDUSTRY OUT REACH')}
                </p>
              </div>
            </div>
            <div className="col-lg-6 mb-5 position-relative">
              <div className="content">
                <h1>04</h1>
                <h2 className=" fw-bold">{t('Business Diversification')}</h2>
                <p className="desc">
                  {t('BEYOND PHARMACEUTICALS, WE’RE EXPLORING DIVERSE AVENUES LIKE MEDICAL RESEARCH AND MARKET EXPANSION TO ENHANCE VALUE AND SEIZE BROADER BUSINESS PROSPECTS.')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-flex align-items-center">
          <div className="col-lg-7 ">
            <div className="title ">{t('Our Mission')}</div>
            <p className="desc ">
            {t("BIOMAB's mission is to provide high quality pharmaceutical and medical products to the Turkish, Middle Eastern and African markets, with the aim of becoming a major contributor to the medical and pharmaceutical industry. The company seeks to establish long-term, mutually beneficial partnerships with prestigious international and local pharmaceutical companies and provide high-quality professional services to its clients.")}
            </p>
          </div>
          <div className="col-lg-5 ">
            <img
              src={require("../../assets/img/5.jpg")}
              alt="BIOMAB"
              width="100%"
            />
          </div>
        </div>
        <div className="row  row-flex row-revers align-items-center ">
          <div className="col-lg-5 ">
            <img
              src={require("../../assets/img/7.png")}
              alt="BIOMAB"
              width="100%"
            />
          </div>
          <div className="col-lg-7 ">
            <div className="title ">{t('Our Vision')}</div>
            <p className="desc ">
            {t("BIOMAB's vision is to be the leading pharmaceutical and medical products provider in Turkey, the Middle East and Africa, known for its excellence and reliability in importing, marketing, promoting, marketing and distributing specialty products and nutritional formulations. The company also aims to expand its services to include more sectors and stakeholders in the pharmaceutical industry in the Middle East and diversify its offerings to include more commercial aspects, such as medical research and medical marketing research.")}
            </p>
          </div>
        </div>
        <div className="row-value">
          
            <div className="title text-center mb-5">{t('Our values')}</div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <h3>{t('PROFESSIONALISM')}</h3>
                <h3>{t('DIVERSIFICATION')}</h3>
                <h3>{t('GENUINE CARE')}</h3>
              </div>
              <div className="col-lg-8">
                <p className="desc">
                {t("They are our pillars of success. We prioritize professionalism for long-term partnerships with major pharmaceutical and medical companies. Diversifying our services ensures a competitive advantage in a difficult market. Paying attention to partners' interests and exceeding expectations ensures our strong leadership position in partnerships")}
                </p>
            
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
};

export default AboutPage;
