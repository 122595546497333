import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .use(LanguageDetector)
  .init({
    resources: {
      tr: {
        translation: {
          // ---links header---
          'home': "Anasayfa",
          "about us": "Hakkımızda", 
          'services': " Hizmetler",
          "contact us": "İletişim",
          'products': "ürünler",
          
          // ---Home Page---
          
          // 1-slider section
          "welcome to biomab": "Biomap'e hoş geldiniz",
          "High-quality Products":"Yüksek kaliteli ürünler",
          "Have confidence, our products are approved by the Turkish Ministry of Health and adhere to the highest international standards.":
          "Güvenin, ürünlerimiz Türkiye Sağlık Bakanlığı tarafından onaylıdır ve en yüksek uluslararası standartlara uygundur.",
          "our services":"Hizmetlerimiz",
          "our products":"bizim ürünlerimiz",

          // 2-about section
          "BIOMAB is a premier provider of pharmaceutical and medical products in Turkey,":"BIOMAB, Türkiye'nin önde gelen ilaç ve tıbbi ürün sağlayıcısıdır,",
          
          "with a reputation for delivering top-quality products and exceptional customer service. BIOMAB Company was established in 2019 under our established group in 2011, the company was founded with the vision of becoming a leading player in the medical and pharmaceutical industry and introducing high-grade products to the Turkish market.":
          "en kaliteli ürünleri ve olağanüstü müşteri hizmetlerini sunma konusundaki itibarıyla. BIOMAB Şirketi 2011 yılında kurulan grubumuz altında 2019 yılında kurulmuş olup, şirket medikal ve ilaç sektöründe lider bir oyuncu olmak ve Türkiye pazarına yüksek kaliteli ürünler sunmak vizyonuyla kurulmuştur.",
          
          "read more":"devamını oku",

          // 3-services section
          "Explore Our Main Service":"Ana Hizmetimizi Keşfedin",
          
          "import":"içe aktarmak",
          
          "Biomab imports the required and unique products through a series of agreements with international medical and pharmaceutical companies...":
          "Biomab, uluslararası medikal ve ilaç firmalarıyla yaptığı bir dizi anlaşma sayesinde ihtiyaç duyulan ve benzersiz ürünleri ithal etmektedir...",

          "export":"ihracat",

          "Biomab exports medical products and supplies from Turkey and countries in which we have partnerships...":
          "Biomab, Türkiye'den ve partnerlik yaptığımız ülkelerden medikal ürün ve malzeme ihracatı yapıyor...",

          "Tenders":"İhaleler",

          "BIOMAB works in cooperation with the health ministries of many countries on the shortage list and also with a lot of non-governmental organizations..":
          "BIOMAB, eksiklik listesinde yer alan birçok ülkenin sağlık bakanlıkları ve ayrıca birçok sivil toplum kuruluşuyla işbirliği içinde çalışmaktadır.",

          "trading":"ticaret",

          "BIOMAB works with many healthcare providers, international warehouses and suppliers in many countries..":
          "BIOMAB birçok ülkede birçok sağlık hizmeti sağlayıcısı, uluslararası depo ve tedarikçiyle çalışmaktadır.",

          "Health tourism":"Sağlık turizmi",

          "Through coordination with many advanced and reliable hospitals and health centers, we provide health and beauty services within high quality standards..":
          "Birçok gelişmiş ve güvenilir hastane ve sağlık merkezi ile koordineli olarak, yüksek kalite standartlarında sağlık ve güzellik hizmetleri sunmaktayız.",

          "medical production":"tıbbi üretim",

          "BIOMAB provides the necessary services to carry out private label manufacturing of cosmetics products and medical supplies..":
          "BIOMAB, kozmetik ürünleri ve tıbbi malzemelerin özel markalı imalatını gerçekleştirmek için gerekli hizmetleri sağlamaktadır.",

          // 4-prosuct section
          "BIOMAB Pharmaceuticals Warehouse provides all kinds of Turkish and international medications. With our extensive range, fulfilling all your requirements has never been easier.":
          "BİOMAB Ecza Deposu, Türk ve uluslararası her türlü ilacı temin etmektedir. Kapsamlı ürün yelpazemiz sayesinde tüm ihtiyaçlarınızı karşılamak hiç bu kadar kolay olmamıştı.",

          "Pharmaceuticals":"İlaçlar",

          "Prescription medicines, OTC medicines, Probiotics, Oncology, Dermatology, Biology and Blood products...":
          "Reçeteli ilaçlar, OTC ilaçlar, Probiyotikler, Onkoloji, Dermatoloji, Biyoloji ve Kan ürünleri...",

          "equipments":"ekipmanlar",

          "All types of local and international devices, tools, supplies and equipment necessary for hospitals...":
          "Hastaneler için gerekli yerli ve uluslararası her türlü cihaz, araç, gereç ve ekipmanlar...",

          "Medical Disposables":"Tıbbi Tek Kullanımlık Ürünler",

          "Various types of local and international, sterile single and multi-use consumables products used in hospitals, nursing rooms...":
          "Hastanelerde, bakım odalarında kullanılan, yerli ve yabancı çeşitli tiplerde, steril tek ve çok kullanımlık sarf malzemeleri...",

          "Laboratory Supplies":"Laboratuvar Malzemeleri",

          "Various types of supplies, tubes, medical containers, reagents, kits, lab tests...":
          "Çeşitli türde sarf malzemeleri, tüpler, tıbbi kaplar, reaktifler, kitler, laboratuvar testleri...",

          "Cosmetics":"Makyaj malzemeleri",

          "The most effective and pure national and international branded products, which are used to increase and support people's quality of life...":
          "İnsanların yaşam kalitesini artırmak ve desteklemek için kullanılan, en etkili ve saf ulusal ve uluslararası markalı ürünler...",

          "Supplements":"Takviyeler",

          "Various types of Vitamins, Minerals, Natural and Vegan Dietary Supplements that are important for public health, to raise immunity...":
          "Halk sağlığı açısından önemli olan, bağışıklığı yükselten çeşitli Vitaminler, Mineraller, Doğal ve Vegan Besin Takviyeleri...",

          //  5-number section

          "PRODUCTS":"ÜRÜNLER",
          "Sales Week":"Satış Haftası",
          "Visits":"Ziyaretler",
          "COUNTRIES":"ÜLKELER",
          "Offices":"Ofisler",

          // 6-References section

          "References":"Referanslar",

          "BIOMAB HAS BRANCHES IN THE MIDDLE EAST AND IS AN APPROVED VENDOR FOR THE UNITED NATIONS (UN) AND VARIOUS NGOS, INCLUDING UNRWA AND UNHCR. OUR TRACK RECORD OF PARTICIPATING IN UN TENDERS AND FULFILLING COMMITMENTS EFFICIENTLY SPEAKS TO OUR COMMITMENT TO EXCELLENCE AND RELIABILITY.":
          "BIOMAB'IN ORTADOĞU'DA ŞUBELERİ BULUNMAKTADIR VE BİRLEŞMİŞ MİLLETLER (BM) VE UNRWA VE BMMYK DAHİL ÇEŞİTLİ STK'LAR İÇİN ONAYLI BİR SATICIDIR. BM İHALELERİNE KATILMA VE TAAHHÜTLERİ VERİMLİ BİR ŞEKİLDE YERİNE GETİRME TARİHİMİZ MÜKEMMELLİK VE GÜVENİLİRLİĞİMİZE BAĞLILIKIMIZI GÖSTERİR.",

          // ---footer links ---

          "links":"Bağlantılar",
          "contact info":"iletişim bilgileri",
          "follow us":"bizi takip edin",
          "Copyright © 2023 WebsArt, Inc.":"Telif Hakkı © 2023 WebsArt, Inc.",


          // ---about page---

          "About BIOMAB Company":"BIOMAB Şirketi Hakkında",

          "BIOMAB is a premier provider of pharmaceutical and medical products in Turkey, with a reputation for delivering top-quality products and exceptional customer service. BIOMAB Company was established in 2019 under our established group in 2011, the company was founded with the vision of becoming a leading player in the medical and pharmaceutical industry and introducing high-grade products to the Turkish market.":
          "BIOMAB, en kaliteli ürünleri ve olağanüstü müşteri hizmetleri sunma konusundaki itibarıyla, Türkiye'nin önde gelen farmasötik ve tıbbi ürün sağlayıcısıdır. BIOMAB Şirketi 2011 yılında kurulan grubumuz altında 2019 yılında kurulmuş olup, şirket medikal ve ilaç sektöründe lider bir oyuncu olmak ve Türkiye pazarına yüksek kaliteli ürünler sunmak vizyonuyla kurulmuştur.",

          "As a full-service importing, exporting, and trading company, BIOMAB has cultivated strong relationships with suppliers and customers from around the globe. We are always seeking new opportunities to expand our reach and broaden our offerings. In addition to serving the Turkish market, BIOMAB has branches in the Middle East and is an approved vendor for the United Nations (UN) and various NGOs, including UNRWA and UNHCR. Our track record of participating in UN tenders and fulfilling commitments efficiently speaks to our commitment to excellence and reliability.":
          "Tam hizmet veren bir ithalat, ihracat ve ticaret şirketi olan BIOMAB, dünya çapındaki tedarikçiler ve müşterilerle güçlü ilişkiler geliştirmiştir. Erişimimizi genişletmek ve tekliflerimizi genişletmek için her zaman yeni fırsatlar arıyoruz. BIOMAB, Türkiye pazarına hizmet vermenin yanı sıra Orta Doğu'da da şubelere sahiptir ve Birleşmiş Milletler (BM) ile UNRWA ve UNHCR dahil olmak üzere çeşitli STK'ların onaylı satıcısıdır. BM ihalelerine katılma ve taahhütleri etkin bir şekilde yerine getirme konusundaki geçmiş performansımız, mükemmellik ve güvenilirliğe olan bağlılığımızın göstergesidir.",

          "To further bolster our position in the market, BIOMAB is actively establishing partnerships with diverse companies and pharmacies throughout the country.":
          "BIOMAB, pazardaki konumumuzu daha da güçlendirmek için ülke çapında çeşitli şirketler ve eczanelerle aktif olarak ortaklıklar kurmaktadır.",

          "These partnerships allow us to tap into our partners expertise in delivering high-quality products, providing superior service, and meeting customer needs and expectations.":
          "Bu ortaklıklar, yüksek kaliteli ürünler sunma, üstün hizmet sunma ve müşteri ihtiyaç ve beklentilerini karşılama konusunda ortaklarımızın uzmanlığından yararlanmamıza olanak tanır.",

          "Organizational Approach":"Organizasyonel Yaklaşım",

          "At BIOMAB, our mission is to become a leading provider of high-quality pharmaceutical and medical products in Turkey, the Middle East, and Africa and to establish ourselves as a major contributor to the medical and pharmaceutical industry. To achieve this, we have developed a comprehensive organizational approach that focuses on the following key areas:":
          "BIOMAB olarak misyonumuz, Türkiye, Orta Doğu ve Afrika'da yüksek kaliteli farmasötik ve tıbbi ürünlerin lider sağlayıcısı olmak ve kendimizi tıbbi ve ilaç endüstrisine önemli katkılarda bulunan bir şirket olarak kurmaktır. Bunu başarmak için aşağıdaki temel alanlara odaklanan kapsamlı bir organizasyonel yaklaşım geliştirdik:",

          "Premium Product Development":"Üstün Ürün Geliştirme",

          "OUR COMMITMENT TO EXCELLENCE DRIVES US TO ANALYZE MARKETS, UNDERSTAND CUSTOMER NEEDS, AND SELECT CUTTING-EDGE PRODUCTS THROUGH PARTNERSHIPS WITH GLOBAL PHARMACEUTICAL LEADERS.":
          "MÜKEMMELLİK TAAHHÜDÜMÜZ, GLOBAL İLAÇ LİDERLERİYLE ORTAKLIKLAR YOLUYLA BİZİ PAZARLARI ANALİZ ETMEYE, MÜŞTERİ İHTİYAÇLARINI ANLAMAYA VE EN SON ÜRÜNLERİ SEÇMEYE YÖNLENDİRİR.",

          "Professional Services":"Profesyonel hizmetler",

          "OUR SERVICE QUALITY MATCHES OUR PREMIUM PRODUCTS. RIGOROUS TRAINING FOR STAFF AND TRINGENT QUALITY STANDARDS ENSURE EXCEPTIONAL CUSTOMER EXPERIENCES.":
          "HİZMET KALİTEMİZ PREMIUM ÜRÜNLERİMİZLE EŞLEŞMEKTEDİR. PERSONEL İÇİN TİTİZ EĞİTİM VE GEÇERLİ KALİTE STANDARTLARI OLAĞANÜSTÜ MÜŞTERİ DENEYİMLERİ SAĞLAR.",

          "Service Expansion":"Hizmet Genişletme",

          "WE’RE EXPANDING BY PARTNERING WITH LOCAL EXPERTISE, ENTERING NEW MARKETS IN THE MIDDLE EAST, AND COLLABORATING WITH DRUGSTORES FOR WIDER PHARMA INDUSTRY OUT REACH":
          "YEREL UZMANLIK İLE ORTAKLIKLAR YAPARAK, ORTADOĞU'DA YENİ PAZARLARA GİREREK VE İLAÇ SEKTÖRÜNÜN DAHA GENİŞ ERİŞİMİ İÇİN Eczanelerle İŞBİRLİĞİ YAPARAK BÜYÜYORUZ",

          "Business Diversification":"İş Çeşitlendirmesi",

          "BEYOND PHARMACEUTICALS, WE’RE EXPLORING DIVERSE AVENUES LIKE MEDICAL RESEARCH AND MARKET EXPANSION TO ENHANCE VALUE AND SEIZE BROADER BUSINESS PROSPECTS.":
          "İLAÇ ÜRÜNLERİNİN ÖTESİNDE, DEĞERİNİ ARTIRMAK VE DAHA GENİŞ İŞ BEKLENTİLERİNİ ELDE ETMEK İÇİN TIBBİ ARAŞTIRMA VE PİYASAYI GENİŞLETME GİBİ FARKLI YOLLARI KEŞFEDİYORUZ.",

          "BIOMAB's mission is to provide high quality pharmaceutical and medical products to the Turkish, Middle Eastern and African markets, with the aim of becoming a major contributor to the medical and pharmaceutical industry. The company seeks to establish long-term, mutually beneficial partnerships with prestigious international and local pharmaceutical companies and provide high-quality professional services to its clients.":

          "BIOMAB'ın misyonu, medikal ve ilaç endüstrisine önemli katkı sağlayan bir şirket olma hedefiyle Türkiye, Orta Doğu ve Afrika pazarlarına yüksek kalitede ilaç ve medikal ürünler sunmaktır. Şirket, prestijli uluslararası ve yerel ilaç şirketleriyle uzun vadeli, karşılıklı yarar sağlayan ortaklıklar kurmayı ve müşterilerine yüksek kalitede profesyonel hizmetler sunmayı amaçlamaktadır.",

          "BIOMAB's vision is to be the leading pharmaceutical and medical products provider in Turkey, the Middle East and Africa, known for its excellence and reliability in importing, marketing, promoting, marketing and distributing specialty products and nutritional formulations. The company also aims to expand its services to include more sectors and stakeholders in the pharmaceutical industry in the Middle East and diversify its offerings to include more commercial aspects, such as medical research and medical marketing research.":

          "BIOMAB'ın vizyonu, özel ürünler ve besin formülasyonlarının ithalatı, pazarlanması, tanıtımı, pazarlaması ve dağıtımında mükemmelliği ve güvenilirliği ile tanınan, Türkiye, Orta Doğu ve Afrika'da lider ilaç ve tıbbi ürün sağlayıcısı olmaktır. Şirket ayrıca Orta Doğu'daki ilaç endüstrisindeki daha fazla sektörü ve paydaşı kapsayacak şekilde hizmetlerini genişletmeyi ve tekliflerini tıbbi araştırma ve tıbbi pazarlama araştırması gibi daha ticari yönleri içerecek şekilde çeşitlendirmeyi hedefliyor.",

          "PROFESSIONALISM":"PROFESYONELLİK",
          "DIVERSIFICATION":"ÇEŞİTLENDİRME",
          "GENUINE CARE":"ORİJİNAL BAKIM",

          "They are our pillars of success. We prioritize professionalism for long-term partnerships with major pharmaceutical and medical companies. Diversifying our services ensures a competitive advantage in a difficult market. Paying attention to partners' interests and exceeding expectations ensures our strong leadership position in partnerships":

          "Onlar bizim başarımızın temel direkleridir. Büyük ilaç ve medikal şirketleriyle uzun vadeli ortaklıklar için profesyonelliğe öncelik veriyoruz. Hizmetlerimizi çeşitlendirmek zorlu bir pazarda rekabet avantajı sağlar. Ortakların çıkarlarına önem vermek ve beklentileri aşmak, ortaklıklarda güçlü liderlik konumumuzu garanti eder",

          "Our Mission":"Görevimiz",
          "Our Vision":"Vizyonumuz",
          "Our values":"Degerlerimiz",

          // ---services page

          "BIOMAB imports the required and unique products through a series of agreements with international medical and pharmaceutical companies and factories to Turkey and the Middle East while ensuring product quality and fast delivery to our various customers.":
          "BIOMAB, ihtiyaç duyulan ve benzersiz ürünleri, uluslararası medikal ve ilaç şirketleri ve fabrikalarla yaptığı bir dizi anlaşma yoluyla Türkiye ve Orta Doğu'ya ithal ederken, çeşitli müşterilerimize ürün kalitesi ve hızlı teslimatı sağlamaktadır.",

          "Biomab exports medical products and supplies from Turkey and countries in which we have partnerships, with high level of guarantees and fast delivery process to our different clients such as health clinics, private hospitals, government institutions, international tenders, international NGOs and cosmetic clinics in more than 25 countries. This includes the best conditions for long-distance cold chain medicine delivery.":
          "Biomab, Türkiye'den ve partnerlik yaptığımız ülkelerden sağlık klinikleri, özel hastaneler, devlet kurumları, uluslararası ihaleler, uluslararası STK'lar ve kozmetik klinikleri gibi farklı müşterilerimize yüksek garantili ve hızlı teslimat süreciyle medikal ürün ve malzemeleri ihraç etmektedir. 25'ten fazla ülke. Buna, uzun mesafeli soğuk zincir ilaç teslimatı için en iyi koşullar da dahildir.",

          "BIOMAB works in cooperation with the health ministries of many countries on the shortage list and also with a lot of non-governmental organizations in different parts of the world. We strive to supply all kinds of products by meeting shipping deadlines and at competitive prices.":
          "BIOMAB, eksiklik listesinde yer alan birçok ülkenin sağlık bakanlıkları ve ayrıca dünyanın farklı yerlerindeki birçok sivil toplum kuruluşuyla iş birliği içinde çalışmaktadır. Her türlü ürünü nakliye sürelerini karşılayarak ve rekabetçi fiyatlarla tedarik etmeye çalışıyoruz.",

          "Through coordination with many advanced and reliable hospitals and health centers, we provide health and beauty services within high quality standards and ensure the follow-up process.":
          "Birçok gelişmiş ve güvenilir hastane ve sağlık merkezi ile koordineli olarak sağlık ve güzellik hizmetlerini yüksek kalite standartlarında sunarak takip sürecini sağlıyoruz.",

          "BIOMAB provides the necessary services to carry out private label manufacturing of cosmetics products and medical supplies through modern technologies within specialized factories.":
          "BIOMAB, kozmetik ürünleri ve tıbbi malzemelerin özel markalı üretiminin modern teknolojilerle uzmanlaşmış fabrikalarda gerçekleştirilmesi için gerekli hizmetleri sağlamaktadır.",

          "BIOMAB works with many healthcare providers, international warehouses and suppliers in many countries to provide customers with the necessary products, improve trade opportunities, and obtain high quality products at reasonable prices.":
          "BIOMAB, müşterilere gerekli ürünleri sağlamak, ticaret fırsatlarını geliştirmek ve yüksek kaliteli ürünleri makul fiyatlarla elde etmek için birçok ülkede birçok sağlık hizmeti sağlayıcısı, uluslararası depo ve tedarikçiyle çalışmaktadır.",

          // ---product page---

          "Prescription medicines, OTC medicines, Probiotics, Oncology, Dermatology, Biology and Blood products.  Anti-infective and vaccines products. Medications for chronic diseases in various pharmaceutical forms and different storage methods.":
          "Reçeteli ilaçlar, OTC ilaçları, Probiyotikler, Onkoloji, Dermatoloji, Biyoloji ve Kan ürünleri. Anti-enfektif ve aşı ürünleri. Kronik hastalıklara yönelik çeşitli farmasötik formlarda ve farklı saklama yöntemlerinde ilaçlar.",

          "All types of local and international devices, tools, supplies and equipment necessary for hospitals and surgical operating rooms, as well as supplies for biological and chemical laboratories, clinics and health centers.":
          "Hastaneler ve ameliyathaneler için gerekli yerli ve uluslararası her türlü cihaz, araç, gereç ve ekipmanlar ile biyolojik ve kimyasal laboratuvarlar, klinikler ve sağlık merkezleri için malzemeler.",


          "Various types of local and international, sterile single and multi-use consumables products used in hospitals, nursing rooms, medical laboratories and medical clinics, including international quality certificates.":
          "Hastaneler, bakım odaları, tıbbi laboratuvarlar ve tıbbi kliniklerde kullanılan, uluslararası kalite sertifikaları da dahil olmak üzere, çeşitli türlerde yerel ve uluslararası, steril tek ve çok kullanımlık sarf malzemeleri.",

          "Various types of supplies, tubes, medical containers, reagents, kits, lab tests and chemical solutions necessary for biological, biochemical and medical research laboratories.":
          "Biyolojik, biyokimyasal ve tıbbi araştırma laboratuvarları için gerekli olan çeşitli türde sarf malzemeleri, tüpler, tıbbi kaplar, reaktifler, kitler, laboratuvar testleri ve kimyasal solüsyonlar.",

          "The most effective and pure national and international branded products, which are used to increase and support people's quality of life. Natural and vegan cosmetic products that achieve maintenance and improvement of skin and hair care as well as personal health and beauty.":
          "İnsanların yaşam kalitesini artırmak ve desteklemek için kullanılan, en etkili ve saf ulusal ve uluslararası markalı ürünler. Cilt ve saç bakımının yanı sıra kişisel sağlık ve güzelliğin korunmasını ve iyileştirilmesini sağlayan doğal ve vegan kozmetik ürünleri.",

          "Various types of Vitamins, Minerals, Natural and Vegan Dietary Supplements that are important for public health, to raise immunity, against some biological problems and to treat nutritional deficiencies which available in various pharmaceutical forms, which facilitates their use and maintenance":
          "Halk sağlığı açısından önemli olan, bağışıklığı artıran, bazı biyolojik sorunlara karşı ve beslenme eksikliklerini tedavi eden, kullanım ve bakımını kolaylaştıran çeşitli farmasötik formlarda bulunan çeşitli Vitaminler, Mineraller, Doğal ve Vegan Besin Takviyeleri",

          // ---contact page---

          "We strive to provide the best service to our customers and ensure your satisfaction.":"Müşterilerimize en iyi hizmeti verip memnuniyetinizi sağlamak için çalışıyoruz.",
          "Get In Touch !!":"Temasta olmak !!",
          "DISTRIBUTION REGIONS":"DAĞITIM BÖLGELERİ",
          "submit":"göndermek",
          "Name":"İsim",
          "Email":"E-posta",
          "Phone Number":"Telefon numarası",
          "Message":"İleti",



           

 

        }
      
      },

      en: {
        translation: {
          "submit":"submit",
          "Our Mission":"Our Mission",
          "Our Vision":"Our Vision",
          "Our values":"Our values",
          'home': "home",
          "about us": "about us", 
          'services': " services",
          "contact us": "contact us",
          'products': "products",
          "welcome to biomab": "welcome to biomab",
          "High-quality Products": "High-quality Products",
          "Have confidence, our products are approved by the Turkish Ministry of Health and adhere to the highest international standards.":
            "Have confidence, our products are approved by the Turkish Ministry of Health and adhere to the highest international standards.",
          "our services": "our services",
          "our products": "our products",
          'BIOMAB is a premier provider of pharmaceutical and medical products in Turkey,':'BIOMAB is a premier provider of pharmaceutical and medical products in Turkey,',
          'and medical products in Turkey, with a reputation for delivering top-quality products and exceptional customer service. BIOMAB Company was established in 2019 under our established group in 2011, the company was founded with the vision of becoming a leading player in the medical and pharmaceutical industry and introducing high-grade products to the Turkish market.':
          'and medical products in Turkey, with a reputation for delivering top-quality products and exceptional customer service. BIOMAB Company was established in 2019 under our established group in 2011, the company was founded with the vision of becoming a leading player in the medical and pharmaceutical industry and introducing high-grade products to the Turkish market.',
'read more':'read more',
'Explore Our Main Service':'Explore Our Main Service',
'import':'import',
'export':'export',
'trading':'trading',
'Health tourism':'Health tourism',
'medical production':'medical production',
'Tenders':'Tenders',
'BIOMAB Pharmaceuticals Warehouse provides all kinds of Turkish and international medications. With our extensive range, fulfilling all your requirements has never been easier.':
'BIOMAB Pharmaceuticals Warehouse provides all kinds of Turkish and international medications. With our extensive range, fulfilling all your requirements has never been easier.',
'Pharmaceuticals':'Pharmaceuticals',
'equipments':'equipments',
'Medical Disposables':'Medical Disposables',
'Laboratory Supplies':'Laboratory Supplies',
'Cosmetics':'Cosmetics',
'Supplements':'Supplements',
'Offices':'Offices',
'COUNTRIES':'COUNTRIES',
'Visits':'Visits',
'Sales Week':'Sales Week',
'PRODUCTS':'PRODUCTS',
'References':'References',
'BIOMAB HAS BRANCHES IN THE MIDDLE EAST AND IS AN APPROVED VENDOR FOR THE UNITED NATIONS (UN) AND VARIOUS NGOS, INCLUDING UNRWA AND UNHCR. OUR TRACK RECORD OF PARTICIPATING IN UN TENDERS AND FULFILLING COMMITMENTS EFFICIENTLY SPEAKS TO OUR COMMITMENT TO EXCELLENCE AND RELIABILITY.':
'BIOMAB HAS BRANCHES IN THE MIDDLE EAST AND IS AN APPROVED VENDOR FOR THE UNITED NATIONS (UN) AND VARIOUS NGOS, INCLUDING UNRWA AND UNHCR. OUR TRACK RECORD OF PARTICIPATING IN UN TENDERS AND FULFILLING COMMITMENTS EFFICIENTLY SPEAKS TO OUR COMMITMENT TO EXCELLENCE AND RELIABILITY.',
'follow us':'follow us',
'contact info':'contact info',
'links':'links',
'Copyright © 2023 WebsArt, Inc.':'Copyright © 2023 WebsArt, Inc.',
'About BIOMAB Company':'About BIOMAB Company',
'BIOMAB is a premier provider of pharmaceutical and medical products in Turkey, with a reputation for delivering top-quality products and exceptional customer service. BIOMAB Company was established in 2019 under our established group in 2011, the company was founded with the vision of becoming a leading player in the medical and pharmaceutical industry and introducing high-grade products to the Turkish market.':
'BIOMAB is a premier provider of pharmaceutical and medical products in Turkey, with a reputation for delivering top-quality products and exceptional customer service. BIOMAB Company was established in 2019 under our established group in 2011, the company was founded with the vision of becoming a leading player in the medical and pharmaceutical industry and introducing high-grade products to the Turkish market.',
'As a full-service importing, exporting, and trading company, BIOMAB has cultivated strong relationships with suppliers and customers from around the globe. We are always seeking new opportunities to expand our reach and broaden our offerings. In addition to serving the Turkish market, BIOMAB has branches in the Middle East and is an approved vendor for the United Nations (UN) and various NGOs, including UNRWA and UNHCR. Our track record of participating in UN tenders and fulfilling commitments efficiently speaks to our commitment to excellence and reliability.':
'As a full-service importing, exporting, and trading company, BIOMAB has cultivated strong relationships with suppliers and customers from around the globe. We are always seeking new opportunities to expand our reach and broaden our offerings. In addition to serving the Turkish market, BIOMAB has branches in the Middle East and is an approved vendor for the United Nations (UN) and various NGOs, including UNRWA and UNHCR. Our track record of participating in UN tenders and fulfilling commitments efficiently speaks to our commitment to excellence and reliability.',
'To further bolster our position in the market, BIOMAB is actively establishing partnerships with diverse companies and pharmacies throughout the country.':
'To further bolster our position in the market, BIOMAB is actively establishing partnerships with diverse companies and pharmacies throughout the country.',
'These partnerships allow us to tap into our partners expertise in delivering high-quality products, providing superior service, and meeting customer needs and expectations.':
'These partnerships allow us to tap into our partners expertise in delivering high-quality products, providing superior service, and meeting customer needs and expectations.',
'Organizational Approach':'Organizational Approach',
'At BIOMAB, our mission is to become a leading provider of high-quality pharmaceutical and medical products in Turkey, the Middle East, and Africa and to establish ourselves as a major contributor to the medical and pharmaceutical industry. To achieve this, we have developed a comprehensive organizational approach that focuses on the following key areas:':
'At BIOMAB, our mission is to become a leading provider of high-quality pharmaceutical and medical products in Turkey, the Middle East, and Africa and to establish ourselves as a major contributor to the medical and pharmaceutical industry. To achieve this, we have developed a comprehensive organizational approach that focuses on the following key areas:',
'Premium Product Development':'Premium Product Development',
'OUR COMMITMENT TO EXCELLENCE DRIVES US TO ANALYZE MARKETS, UNDERSTAND CUSTOMER NEEDS, AND SELECT CUTTING-EDGE PRODUCTS THROUGH PARTNERSHIPS WITH GLOBAL PHARMACEUTICAL LEADERS.':
'OUR COMMITMENT TO EXCELLENCE DRIVES US TO ANALYZE MARKETS, UNDERSTAND CUSTOMER NEEDS, AND SELECT CUTTING-EDGE PRODUCTS THROUGH PARTNERSHIPS WITH GLOBAL PHARMACEUTICAL LEADERS.',
'Professional Services':'Professional Services',
'OUR SERVICE QUALITY MATCHES OUR PREMIUM PRODUCTS. RIGOROUS TRAINING FOR STAFF AND TRINGENT QUALITY STANDARDS ENSURE EXCEPTIONAL CUSTOMER EXPERIENCES.':
'OUR SERVICE QUALITY MATCHES OUR PREMIUM PRODUCTS. RIGOROUS TRAINING FOR STAFF AND TRINGENT QUALITY STANDARDS ENSURE EXCEPTIONAL CUSTOMER EXPERIENCES.',
'Service Expansion':'Service Expansion',
'WE’RE EXPANDING BY PARTNERING WITH LOCAL EXPERTISE, ENTERING NEW MARKETS IN THE MIDDLE EAST, AND COLLABORATING WITH DRUGSTORES FOR WIDER PHARMA INDUSTRY OUT REACH':
'WE’RE EXPANDING BY PARTNERING WITH LOCAL EXPERTISE, ENTERING NEW MARKETS IN THE MIDDLE EAST, AND COLLABORATING WITH DRUGSTORES FOR WIDER PHARMA INDUSTRY OUT REACH',
'Business Diversification':'Business Diversification',
'BEYOND PHARMACEUTICALS, WE’RE EXPLORING DIVERSE AVENUES LIKE MEDICAL RESEARCH AND MARKET EXPANSION TO ENHANCE VALUE AND SEIZE BROADER BUSINESS PROSPECTS.':
'BEYOND PHARMACEUTICALS, WE’RE EXPLORING DIVERSE AVENUES LIKE MEDICAL RESEARCH AND MARKET EXPANSION TO ENHANCE VALUE AND SEIZE BROADER BUSINESS PROSPECTS.',
'BIOMAB imports required and unique products through a series of agreements with exporting countries with product quality guarantees and fast delivery to our various customers.':
'BIOMAB imports required and unique products through a series of agreements with exporting countries with product quality guarantees and fast delivery to our various customers.',
'BIOMAB exports medical products with guarantees and fast delivery to our various clients such as health clinics, private hospitals, government institutions, international tenders, international NGOs and cosmetic clinics in more than 25 countries. This includes the best conditions for cold chain medicines that are delivered over long distances.':
'BIOMAB exports medical products with guarantees and fast delivery to our various clients such as health clinics, private hospitals, government institutions, international tenders, international NGOs and cosmetic clinics in more than 25 countries. This includes the best conditions for cold chain medicines that are delivered over long distances.',
'BIOMAB works in cooperation with the health ministries of many countries on the shortage list and also with a lot of non-governmental organizations in different parts of the world. We strive to supply all kinds of products by meeting shipping deadlines and at competitive prices.':
'BIOMAB works in cooperation with the health ministries of many countries on the shortage list and also with a lot of non-governmental organizations in different parts of the world. We strive to supply all kinds of products by meeting shipping deadlines and at competitive prices.',
'BIOMAB works with many healthcare providers, international warehouses and suppliers in many countries to provide customers with the necessary products, improve trade opportunities, and obtain high quality products at reasonable prices.':
'BIOMAB works with many healthcare providers, international warehouses and suppliers in many countries to provide customers with the necessary products, improve trade opportunities, and obtain high quality products at reasonable prices.',
'Through coordination with many advanced and reliable hospitals and health centers, we provide health and beauty services within high quality standards and ensure the follow-up process.':
'Through coordination with many advanced and reliable hospitals and health centers, we provide health and beauty services within high quality standards and ensure the follow-up process.',
'BIOMAB provides the necessary services to carry out private label manufacturing of cosmetics products and medical supplies through modern technologies within specialized factories.':
'BIOMAB provides the necessary services to carry out private label manufacturing of cosmetics products and medical supplies through modern technologies within specialized factories.',
'BIOMAB imports required and unique products through a series of agreements with exporting countries..':
'BIOMAB imports required and unique products through a series of agreements with exporting countries..',
'BIOMAB exports medical products with guarantees and fast delivery to our various clients such as health clinics, private hospitals..':
'BIOMAB exports medical products with guarantees and fast delivery to our various clients such as health clinics, private hospitals..',
'BIOMAB works in cooperation with the health ministries of many countries on the shortage list and also with a lot of non-governmental organizations..':
'BIOMAB works in cooperation with the health ministries of many countries on the shortage list and also with a lot of non-governmental organizations..',
'BIOMAB works with many healthcare providers, international warehouses and suppliers in many countries..':
'BIOMAB works with many healthcare providers, international warehouses and suppliers in many countries..',
'Through coordination with many advanced and reliable hospitals and health centers, we provide health and beauty services within high quality standards..':
'Through coordination with many advanced and reliable hospitals and health centers, we provide health and beauty services within high quality standards..',
'BIOMAB provides the necessary services to carry out private label manufacturing of cosmetics products and medical supplies..':
'BIOMAB provides the necessary services to carry out private label manufacturing of cosmetics products and medical supplies..',
'Prescription medicines, OTC medicines, Probiotics, Oncology, Dermatology, Biology and Blood products.  Anti-infective and vaccines products. Medications for chronic diseases in various pharmaceutical forms and different storage methods.':
'Prescription medicines, OTC medicines, Probiotics, Oncology, Dermatology, Biology and Blood products.  Anti-infective and vaccines products. Medications for chronic diseases in various pharmaceutical forms and different storage methods.',
'All types of devices, tools, supplies and equipment required for hospitals, medical operating and surgery rooms, laboratories , clinics and health centers of local and international brands.':
'All types of devices, tools, supplies and equipment required for hospitals, medical operating and surgery rooms, laboratories , clinics and health centers of local and international brands.',
'All types of sterile and multi-use consumables products used in hospitals, nursing rooms, laboratories and medical clinics':
'All types of sterile and multi-use consumables products used in hospitals, nursing rooms, laboratories and medical clinics',
'Various types of supplies, tubes, medical containers, reagents, kits, and solutions necessary for medical and research laboratories.':
'Various types of supplies, tubes, medical containers, reagents, kits, and solutions necessary for medical and research laboratories.',
"The best national and international branded products, which are used to increase and support people's quality of life. Products that achieve maintenance and improvement of skin care, hair care and personal health and beauty.":
"The best national and international branded products, which are used to increase and support people's quality of life. Products that achieve maintenance and improvement of skin care, hair care and personal health and beauty.",
"Various types of vitamins, minerals, natural and nutritional food supplements that are important for public health, to raise immunity, and to treat their nutritional deficiencies in various pharmaceutical forms, which facilitates their use and maintenance.":
"Various types of vitamins, minerals, natural and nutritional food supplements that are important for public health, to raise immunity, and to treat their nutritional deficiencies in various pharmaceutical forms, which facilitates their use and maintenance.",
"Prescription medicines, OTC  medicines, Probiotics,  Oncology, Dermatology, Biology, Hemophilia...":
"Prescription medicines, OTC  medicines, Probiotics,  Oncology, Dermatology, Biology, Hemophilia...",
"All types of devices, tools, supplies and equipment required for hospitals, medical operating and surgery rooms..":
"All types of devices, tools, supplies and equipment required for hospitals, medical operating and surgery rooms..",
"All types of sterile and multi-use consumables products used in hospitals..":
"All types of sterile and multi-use consumables products used in hospitals..",
"Various types of supplies, tubes, medical containers, reagents, kits..":
"Various types of supplies, tubes, medical containers, reagents, kits..",
"The best national and international branded products, which are used to increase and support people's quality of life..":
"The best national and international branded products, which are used to increase and support people's quality of life..",
"Various types of vitamins, minerals, natural and nutritional food supplements that are important for public health..":
"Various types of vitamins, minerals, natural and nutritional food supplements that are important for public health..",
"BIOMAB imports the required and unique products through a series of agreements with international medical and pharmaceutical companies and factories to Turkey and the Middle East while ensuring product quality and fast delivery to our various customers.":
"BIOMAB imports the required and unique products through a series of agreements with international medical and pharmaceutical companies and factories to Turkey and the Middle East while ensuring product quality and fast delivery to our various customers.",
"BIOMAB exports medical products and supplies from Turkey and countries in which we have partnerships, with high level of guarantees and fast delivery process to our different clients such as health clinics, private hospitals, government institutions, international tenders, international NGOs and cosmetic clinics in more than 25 countries. This includes the best conditions for long-distance cold chain medicine delivery.":
"BIOMAB exports medical products and supplies from Turkey and countries in which we have partnerships, with high level of guarantees and fast delivery process to our different clients such as health clinics, private hospitals, government institutions, international tenders, international NGOs and cosmetic clinics in more than 25 countries. This includes the best conditions for long-distance cold chain medicine delivery.",
"All types of local and international devices, tools, supplies and equipment necessary for hospitals and surgical operating rooms, as well as supplies for biological and chemical laboratories, clinics and health centers.":
"All types of local and international devices, tools, supplies and equipment necessary for hospitals and surgical operating rooms, as well as supplies for biological and chemical laboratories, clinics and health centers.",
"Various types of local and international, sterile single and multi-use consumables products used in hospitals, nursing rooms, medical laboratories and medical clinics, including international quality certificates.":
"Various types of local and international, sterile single and multi-use consumables products used in hospitals, nursing rooms, medical laboratories and medical clinics, including international quality certificates.",
"Various types of supplies, tubes, medical containers, reagents, kits, lab tests and chemical solutions necessary for biological, biochemical and medical research laboratories.":
"Various types of supplies, tubes, medical containers, reagents, kits, lab tests and chemical solutions necessary for biological, biochemical and medical research laboratories.",
"The most effective and pure national and international branded products, which are used to increase and support people's quality of life. Natural and vegan cosmetic products that achieve maintenance and improvement of skin and hair care as well as personal health and beauty.":
"The most effective and pure national and international branded products, which are used to increase and support people's quality of life. Natural and vegan cosmetic products that achieve maintenance and improvement of skin and hair care as well as personal health and beauty.",
"Various types of Vitamins, Minerals, Natural and Vegan Dietary Supplements that are important for public health, to raise immunity, against some biological problems and to treat nutritional deficiencies which available in various pharmaceutical forms, which facilitates their use and maintenance":
"Various types of Vitamins, Minerals, Natural and Vegan Dietary Supplements that are important for public health, to raise immunity, against some biological problems and to treat nutritional deficiencies which available in various pharmaceutical forms, which facilitates their use and maintenance",
"BIOMAB's mission is to provide high quality pharmaceutical and medical products to the Turkish, Middle Eastern and African markets, with the aim of becoming a major contributor to the medical and pharmaceutical industry. The company seeks to establish long-term, mutually beneficial partnerships with prestigious international and local pharmaceutical companies and provide high-quality professional services to its clients.":
"BIOMAB's mission is to provide high quality pharmaceutical and medical products to the Turkish, Middle Eastern and African markets, with the aim of becoming a major contributor to the medical and pharmaceutical industry. The company seeks to establish long-term, mutually beneficial partnerships with prestigious international and local pharmaceutical companies and provide high-quality professional services to its clients.",
"BIOMAB's vision is to be the leading pharmaceutical and medical products provider in Turkey, the Middle East and Africa, known for its excellence and reliability in importing, marketing, promoting, marketing and distributing specialty products and nutritional formulations. The company also aims to expand its services to include more sectors and stakeholders in the pharmaceutical industry in the Middle East and diversify its offerings to include more commercial aspects, such as medical research and medical marketing research.":
"BIOMAB's vision is to be the leading pharmaceutical and medical products provider in Turkey, the Middle East and Africa, known for its excellence and reliability in importing, marketing, promoting, marketing and distributing specialty products and nutritional formulations. The company also aims to expand its services to include more sectors and stakeholders in the pharmaceutical industry in the Middle East and diversify its offerings to include more commercial aspects, such as medical research and medical marketing research.",
"PROFESSIONALISM":"PROFESSIONALISM",
"DIVERSIFICATION":"DIVERSIFICATION",
"GENUINE CARE":"GENUINE CARE",
"They are our pillars of success. We prioritize professionalism for long-term partnerships with major pharmaceutical and medical companies. Diversifying our services ensures a competitive advantage in a difficult market. Paying attention to partners' interests and exceeding expectations ensures our strong leadership position in partnerships":
"They are our pillars of success. We prioritize professionalism for long-term partnerships with major pharmaceutical and medical companies. Diversifying our services ensures a competitive advantage in a difficult market. Paying attention to partners' interests and exceeding expectations ensures our strong leadership position in partnerships",
"Biomab exports medical products and supplies from Turkey and countries in which we have partnerships, with high level of guarantees and fast delivery process to our different clients such as health clinics, private hospitals, government institutions, international tenders, international NGOs and cosmetic clinics in more than 25 countries. This includes the best conditions for long-distance cold chain medicine delivery.":
"Biomab exports medical products and supplies from Turkey and countries in which we have partnerships, with high level of guarantees and fast delivery process to our different clients such as health clinics, private hospitals, government institutions, international tenders, international NGOs and cosmetic clinics in more than 25 countries. This includes the best conditions for long-distance cold chain medicine delivery.",
"Biomab imports the required and unique products through a series of agreements with international medical and pharmaceutical companies...":
"Biomab imports the required and unique products through a series of agreements with international medical and pharmaceutical companies...",
"Biomab exports medical products and supplies from Turkey and countries in which we have partnerships...":
"Biomab exports medical products and supplies from Turkey and countries in which we have partnerships...",
"Prescription medicines, OTC medicines, Probiotics, Oncology, Dermatology, Biology and Blood products...":
"Prescription medicines, OTC medicines, Probiotics, Oncology, Dermatology, Biology and Blood products...",
"All types of local and international devices, tools, supplies and equipment necessary for hospitals...":
"All types of local and international devices, tools, supplies and equipment necessary for hospitals...",
"Various types of local and international, sterile single and multi-use consumables products used in hospitals, nursing rooms...":
"Various types of local and international, sterile single and multi-use consumables products used in hospitals, nursing rooms...",
"Various types of supplies, tubes, medical containers, reagents, kits, lab tests...":
"Various types of supplies, tubes, medical containers, reagents, kits, lab tests...",
"The most effective and pure national and international branded products, which are used to increase and support people's quality of life...":
"The most effective and pure national and international branded products, which are used to increase and support people's quality of life...",
"Various types of Vitamins, Minerals, Natural and Vegan Dietary Supplements that are important for public health, to raise immunity...":
"Various types of Vitamins, Minerals, Natural and Vegan Dietary Supplements that are important for public health, to raise immunity...",
"We strive to provide the best service to our customers and ensure your satisfaction.":"We strive to provide the best service to our customers and ensure your satisfaction.",
"Get In Touch !!":"Get In Touch !!",
"DISTRIBUTION REGIONS":"DISTRIBUTION REGIONS",
"Name":"Name",
"Email":"Email",
"Phone Number":"Phone Number",
"Message":"Message",







        },
      },
      ar: {
        translation: {
          "Name":"الاسم",
          "Email":"البريد الإلكتروني",
          "Phone Number":"رقم الهاتف",
          "Message":"رسالة",
          "submit":"ارسال",
          "DISTRIBUTION REGIONS":"مناطق التوزيع",
          "We strive to provide the best service to our customers and ensure your satisfaction.":"نحن نسعى جاهدين لتقديم أفضل الخدمات لعملائنا وضمان رضاكم.",
          "Get In Touch !!":"ابق على تواصل !!",
          "Our Mission":"مهمتنا",
          "Our Vision":"رؤيتنا",
          "Our values":"قيمنا",
          "Various types of Vitamins, Minerals, Natural and Vegan Dietary Supplements that are important for public health, to raise immunity...":
          "أنواع مختلفة من الفيتامينات والمعادن والمكملات الغذائية الطبيعية والنباتية ذات الأهمية لصحة الانسان العامة و لرفع المناعة",
          "The most effective and pure national and international branded products, which are used to increase and support people's quality of life...":
          "المنتجات ذات العلامات التجارية الوطنية والدولية الأكثر فعالية ونقاء، والتي تستخدم لزيادة ودعم الصحة العامة",
          "Various types of supplies, tubes, medical containers, reagents, kits, lab tests...":
          "أنواع مختلفة من مستلزمات المخابر من الأنابيب والاوعية والكواشف وكيتات الفحوصات المخبرية",
          "Various types of local and international, sterile single and multi-use consumables products used in hospitals, nursing rooms...":
          " أنواع المنتجات الاستهلاكية المحلية والعالمية المعقمة المخصصة للاستخدام لمرة واحدة او متعددة الاستخدام",
          "All types of local and international devices, tools, supplies and equipment necessary for hospitals...":
          "جميع أنواع الأجهزة والأدوات والمستلزمات والمعدات الطبية المحلية والعالمية اللازمة للمستشفيات",
          "Prescription medicines, OTC medicines, Probiotics, Oncology, Dermatology, Biology and Blood products...":
          "ادوية الوصفات الطبية، والأدوية التي لا تحتاج إلى وصفة طبية. البروبيوتيك، ادوية الأورام و الأمراض الجلدية، والادوية الحيوية ، ومنتجات الدم",
          "Biomab exports medical products and supplies from Turkey and countries in which we have partnerships...":
          "تقوم BIOMAB بتصدير المنتجات والمستلزمات الطبية من تركيا والدول التي لدينا شراكات فيها...",
          "Biomab imports the required and unique products through a series of agreements with international medical and pharmaceutical companies...":
          "تقوم شركة BIOMAB باستيراد المنتجات المطلوبة والفريدة من نوعها من خلال سلسلة من الاتفاقيات مع الشركات...",
          'home': "الرئيسية",
          "about us": "من نحن",
          'services': "الخدمات",
          'products': "المنتجات",
          "contact us": "اتصل بنا",
          "welcome to biomab": "biomab مرحبا بك في ",
          "High-quality Products": "منتجات ذات جودة عالية",
          "Have confidence, our products are approved by the Turkish Ministry of Health and adhere to the highest international standards.":
            "ثق بأن منتجاتنا معتمدة من وزارة الصحة التركية وتلتزم بأعلى المعايير العالمية",
          "our services": "خدماتنا",
          "our products": "منتجاتنا",
          'BIOMAB is a premier provider of pharmaceutical and medical products in Turkey,':'BIOMAB هي شركة رائدة في مجال توفير الأدوية والمنتجات الطبية في تركيا،',
          'with a reputation for delivering top-quality products and exceptional customer service. BIOMAB Company was established in 2019 under our established group in 2011, the company was founded with the vision of becoming a leading player in the medical and pharmaceutical industry and introducing high-grade products to the Turkish market.':
          ' مع سمعة جيدة في تقديم منتجات عالية الجودة وخدمة عملاء استثنائية. تأسست شركة BIOMAB في عام 2019 ضمن مجموعتنا المنشأة في عام 2011، وقد تأسست الشركة بهدف أن تصبح لاعبًا رائدًا في الصناعة الطبية والصيدلانية وتقديم منتجات عالية الجودة إلى السوق التركية.',
          'read more':'عرض المزيد',
          'Explore Our Main Service':'استكشف خدمتنا الرئيسية',
          'import':'الاستيراد',
          'export':'التصدير',
          'trading':'التجارة',
          'Health tourism':'السياحة العلاجية',
          'medical production':'الإنتاج الطبي',
          'Tenders':'المناقصات',
          'BIOMAB Pharmaceuticals Warehouse provides all kinds of Turkish and international medications. With our extensive range, fulfilling all your requirements has never been easier.':
          'يوفر مستودع BIOMAB للأدوية جميع أنواع الأدوية التركية والعالمية. بفضل مجموعتنا الواسعة، أصبح تلبية جميع متطلباتك أسهل من أي وقت مضى.',
          'Pharmaceuticals':'الأدوية الطبية',
          'equipments':'المعدات الطبية',
          "BIOMAB's mission is to provide high quality pharmaceutical and medical products to the Turkish, Middle Eastern and African markets, with the aim of becoming a major contributor to the medical and pharmaceutical industry. The company seeks to establish long-term, mutually beneficial partnerships with prestigious international and local pharmaceutical companies and provide high-quality professional services to its clients.":
          "تتمثل مهمة BIOMAB في تقديم منتجات صيدلانية وطبية عالية الجودة إلى الأسواق التركية والشرق أوسطية وأفريقيا، بهدف أن تصبح مساهمًا رئيسيًا في الصناعة الطبية والصيدلانية. تسعى الشركة إلى إقامة شراكات طويلة الأمد ذات منفعة متبادلة مع شركات الأدوية الدولية والمحلية المرموقة وتقديم خدمات احترافية عالية الجودة لعملائها.",
'Medical Disposables':'المستهلكات الطبية',
'Laboratory Supplies':'مستلزمات المختبرات',
'Cosmetics':'المستحضرات التجميلية',
'Supplements':'المكملات الغذائية',
'Offices':'المكاتب',
'COUNTRIES':'البلدان',
'Visits':'الزيارات',
'Sales Week':'المبيعات',
'PRODUCTS':'المنتجات',
'References':'المراجع',
'BIOMAB HAS BRANCHES IN THE MIDDLE EAST AND IS AN APPROVED VENDOR FOR THE UNITED NATIONS (UN) AND VARIOUS NGOS, INCLUDING UNRWA AND UNHCR. OUR TRACK RECORD OF PARTICIPATING IN UN TENDERS AND FULFILLING COMMITMENTS EFFICIENTLY SPEAKS TO OUR COMMITMENT TO EXCELLENCE AND RELIABILITY.':
'لدى BIOMAB فروع في الشرق الأوسط، وهو بائع معتمد للأمم المتحدة والعديد من المنظمات غير الحكومية، بما في ذلك الأونروا ومفوضية الأمم المتحدة لشؤون اللاجئين. إن سجلنا الحافل بالمشاركة في مناقصات الأمم المتحدة والوفاء بالالتزامات يعكس بكفاءة التزامنا بالتميز والموثوقية.',
'follow us':'تابعنا ',
'contact info':'معلومات التواصل',
'links':'روابط',
'Copyright © 2023 WebsArt, Inc.':'حقوق النشر © 2023 WebsArt, Inc.',
'About BIOMAB Company':' حول شركة  BIOMAB',
'BIOMAB is a premier provider of pharmaceutical and medical products in Turkey, with a reputation for delivering top-quality products and exceptional customer service. BIOMAB Company was established in 2019 under our established group in 2011, the company was founded with the vision of becoming a leading player in the medical and pharmaceutical industry and introducing high-grade products to the Turkish market.':
'BIOMAB هي المزود الأول للمنتجات الصيدلانية والطبية في تركيا، وتتمتع بسمعة طيبة في تقديم منتجات عالية الجودة وخدمة عملاء استثنائية. تأسست شركة BIOMAB في عام 2019 ضمن مجموعتنا المنشأة في عام 2011، وقد تأسست الشركة بهدف أن تصبح لاعبًا رائدًا في الصناعة الطبية والصيدلانية وتقديم منتجات عالية الجودة إلى السوق التركية.',
'As a full-service importing, exporting, and trading company, BIOMAB has cultivated strong relationships with suppliers and customers from around the globe. We are always seeking new opportunities to expand our reach and broaden our offerings. In addition to serving the Turkish market, BIOMAB has branches in the Middle East and is an approved vendor for the United Nations (UN) and various NGOs, including UNRWA and UNHCR. Our track record of participating in UN tenders and fulfilling commitments efficiently speaks to our commitment to excellence and reliability.':
'باعتبارها شركة استيراد وتصدير وتجارة متكاملة الخدمات، قامت BIOMAB بتكوين علاقات قوية مع الموردين والعملاء من جميع أنحاء العالم. نحن نبحث دائمًا عن فرص جديدة لتوسيع نطاق وصولنا وتوسيع عروضنا. بالإضافة إلى خدمة السوق التركية، لدى BIOMAB فروع في الشرق الأوسط وهي بائع معتمد للأمم المتحدة (UN) والعديد من المنظمات غير الحكومية، بما في ذلك الأونروا ومفوضية الأمم المتحدة لشؤون اللاجئين. إن سجلنا الحافل بالمشاركة في مناقصات الأمم المتحدة والوفاء بالالتزامات بكفاءة يعكس التزامنا بالتميز والموثوقية.',
'To further bolster our position in the market, BIOMAB is actively establishing partnerships with diverse companies and pharmacies throughout the country.':
'لتعزيز مكانتنا في السوق بشكل أكبر، تعمل BIOMAB بنشاط على إقامة شراكات مع شركات وصيدليات متنوعة في جميع أنحاء البلاد.',
'These partnerships allow us to tap into our partners expertise in delivering high-quality products, providing superior service, and meeting customer needs and expectations.':
'تتيح لنا هذه الشراكات الاستفادة من خبرات شركائنا في تقديم منتجات عالية الجودة، وتوفير خدمة متميزة، وتلبية احتياجات العملاء وتوقعاتهم.',
'Organizational Approach':'النهج التنظيمي',
'At BIOMAB, our mission is to become a leading provider of high-quality pharmaceutical and medical products in Turkey, the Middle East, and Africa and to establish ourselves as a major contributor to the medical and pharmaceutical industry. To achieve this, we have developed a comprehensive organizational approach that focuses on the following key areas:':
'تتمثل مهمتنا في BIOMAB في أن نصبح مزودًا رائدًا للمنتجات الصيدلانية والطبية عالية الجودة في تركيا والشرق الأوسط وأفريقيا وأن نثبت أنفسنا كمساهم رئيسي في الصناعة الطبية والصيدلانية. ولتحقيق ذلك، قمنا بتطوير نهج تنظيمي شامل يركز على المجالات الرئيسية التالية:',
'Premium Product Development':'تطوير المنتجات المتميزة',
'OUR COMMITMENT TO EXCELLENCE DRIVES US TO ANALYZE MARKETS, UNDERSTAND CUSTOMER NEEDS, AND SELECT CUTTING-EDGE PRODUCTS THROUGH PARTNERSHIPS WITH GLOBAL PHARMACEUTICAL LEADERS.':
'إن التزامنا بالتميز يدفعنا إلى تحليل الأسواق وفهم احتياجات العملاء واختيار المنتجات المتطورة من خلال الشراكات مع رواد صناعة الأدوية العالميين.',
'Professional Services':'خدمات احترافية',
'OUR SERVICE QUALITY MATCHES OUR PREMIUM PRODUCTS. RIGOROUS TRAINING FOR STAFF AND TRINGENT QUALITY STANDARDS ENSURE EXCEPTIONAL CUSTOMER EXPERIENCES.':
'جودة خدمتنا تتطابق مع منتجاتنا المتميزة. التدريب الصارم للموظفين ومعايير الجودة الصارمة تضمن تجارب العملاء الاستثنائية.',
'Service Expansion':'توسيع الخدمة',
'WE’RE EXPANDING BY PARTNERING WITH LOCAL EXPERTISE, ENTERING NEW MARKETS IN THE MIDDLE EAST, AND COLLABORATING WITH DRUGSTORES FOR WIDER PHARMA INDUSTRY OUT REACH':
'نحن نتوسع من خلال الشراكة مع الخبرات المحلية، ودخول أسواق جديدة في الشرق الأوسط، والتعاون مع الصيدليات لتوسيع نطاق صناعة الأدوية.',
'Business Diversification':'تنويع الأعمال',
'BEYOND PHARMACEUTICALS, WE’RE EXPLORING DIVERSE AVENUES LIKE MEDICAL RESEARCH AND MARKET EXPANSION TO ENHANCE VALUE AND SEIZE BROADER BUSINESS PROSPECTS.':
'بعيدًا عن المستحضرات الصيدلانية، نحن نستكشف طرقًا متنوعة مثل الأبحاث الطبية والتوسع في السوق لتعزيز القيمة واغتنام آفاق أعمال أوسع.',
'BIOMAB imports required and unique products through a series of agreements with exporting countries with product quality guarantees and fast delivery to our various customers.':
'تقوم شركة Biomab باستيراد المنتجات المطلوبة والفريدة من نوعها من خلال سلسلة من الاتفاقيات مع الشركات و معامل التصنيع الطبية والدوائية العالمية إلى تركيا والشرق الأوسط مع ضمان جودة المنتج وسرعة التسليم لمختلف عملائنا.',
'BIOMAB exports medical products with guarantees and fast delivery to our various clients such as health clinics, private hospitals, government institutions, international tenders, international NGOs and cosmetic clinics in more than 25 countries. This includes the best conditions for cold chain medicines that are delivered over long distances.':
'تقوم بيوماب بتصدير المنتجات والمستلزمات الطبية من تركيا والدول التي لدينا شراكات فيها، على مستوى عال من الضمانات وسرعة عملية النقل و التسليم لعملائنا المختلفين مثل العيادات الصحية والمستشفيات الخاصة والحكومية و المؤسسات الطارحة للمناقصات الدولية والمنظمات غير الحكومية الدولية وكذلك عيادات التجميل والصحة العامة في مايزيد عن 25 دولة. وهذا يشمل أفضل الظروف الملائمة لتوصيل أدوية سلسلة التبريد لمسافات طويلة.',
'BIOMAB works in cooperation with the health ministries of many countries on the shortage list and also with a lot of non-governmental organizations in different parts of the world. We strive to supply all kinds of products by meeting shipping deadlines and at competitive prices.':
'يعمل BIOMAB بالتعاون مع وزارات الصحة في العديد من البلدان المدرجة في قائمة النقص وأيضا مع الكثير من المنظمات غير الحكومية في أنحاء مختلفة من العالم. نحن نسعى جاهدين لتوريد جميع أنواع المنتجات من خلال الالتزام بالمواعيد النهائية للشحن وبأسعار تنافسية.',
'BIOMAB works with many healthcare providers, international warehouses and suppliers in many countries to provide customers with the necessary products, improve trade opportunities, and obtain high quality products at reasonable prices.':
'تعمل BIOMAB مع العديد من مقدمي الرعاية الصحية والمستودعات الدولية والموردين في العديد من البلدان لتزويد العملاء بالمنتجات الضرورية وتحسين الفرص التجارية والحصول على منتجات عالية الجودة وبأسعار معقولة.',
'Through coordination with many advanced and reliable hospitals and health centers, we provide health and beauty services within high quality standards and ensure the follow-up process.':
'من خلال التنسيق مع العديد من المستشفيات والمراكز الصحية ومراكز السياحة العلاجية المتطورة والموثوقة، نقدم خدمات الصحة والتجميل ضمن معايير الجودة العالية مع ضمان عملية المتابعة.',
'BIOMAB provides the necessary services to carry out private label manufacturing of cosmetics products and medical supplies through modern technologies within specialized factories.':
'تقدم شركة بيوماب الخدمات اللازمة للقيام بتصنيع العلامات التجارية الخاصة لمنتجات التجميل والمستلزمات الطبية من خلال التقنيات الحديثة داخل المصانع المتخصصة.',
'BIOMAB exports medical products with guarantees and fast delivery to our various clients such as health clinics, private hospitals..':
'تقوم BIOMAB بتصدير المنتجات الطبية مع الضمانات والتوصيل السريع لمختلف عملائنا مثل العيادات الصحية والمستشفيات الخاصة..',
'BIOMAB works in cooperation with the health ministries of many countries on the shortage list and also with a lot of non-governmental organizations..':
'يعمل BIOMAB بالتعاون مع وزارات الصحة في العديد من الدول المدرجة في قائمة النقص وأيضا مع الكثير من المنظمات غير الحكومية..',
'BIOMAB works with many healthcare providers, international warehouses and suppliers in many countries..':
'يعمل BIOMAB مع العديد من مقدمي الرعاية الصحية والمستودعات الدولية والموردين في العديد من البلدان..',
'Through coordination with many advanced and reliable hospitals and health centers, we provide health and beauty services within high quality standards..':
' من خلال التنسيق مع العديد من المستشفيات والمراكز الصحية ومراكز السياحة العلاجية المتطورة والموثوقة...',
'BIOMAB provides the necessary services to carry out private label manufacturing of cosmetics products and medical supplies..':
'تقدم بيوماب الخدمات اللازمة للقيام بتصنيع العلامات التجارية الخاصة لمنتجات التجميل والمستلزمات الطبية.',
'Prescription medicines, OTC medicines, Probiotics, Oncology, Dermatology, Biology and Blood products.  Anti-infective and vaccines products. Medications for chronic diseases in various pharmaceutical forms and different storage methods.':
'ادوية الوصفات الطبية، والأدوية التي لا تحتاج إلى وصفة طبية. البروبيوتيك، ادوية الأورام و الأمراض الجلدية، والادوية الحيوية ، ومنتجات الدم. منتجات مكافحة العدوى ومضادات الالتهاب واللقاحات. أدوية الأمراض المزمنة بأشكالها الصيدلانية وطرق تخزينها المختلفة.',
'All types of devices, tools, supplies and equipment required for hospitals, medical operating and surgery rooms, laboratories , clinics and health centers of local and international brands.':
'جميع أنواع الأجهزة والأدوات والمستلزمات والمعدات اللازمة للمستشفيات وغرف العمليات والجراحات الطبية والمختبرات والعيادات والمراكز الصحية ذات الماركات المحلية والعالمية.',
'All types of sterile and multi-use consumables products used in hospitals, nursing rooms, laboratories and medical clinics':
'جميع أنواع المنتجات الاستهلاكية المعقمة والمتعددة الاستخدام المستخدمة في المستشفيات وغرف التمريض والمختبرات والعيادات الطبية',
'Various types of supplies, tubes, medical containers, reagents, kits, and solutions necessary for medical and research laboratories.':
'مختلف أنواع المستلزمات والأنابيب والحاويات الطبية والكواشف والأطقم والمحاليل اللازمة للمختبرات الطبية والبحثية.',
"The best national and international branded products, which are used to increase and support people's quality of life. Products that achieve maintenance and improvement of skin care, hair care and personal health and beauty.":
"أفضل المنتجات ذات العلامات التجارية الوطنية والعالمية، والتي تستخدم لزيادة ودعم نوعية حياة الناس. المنتجات التي تحقق صيانة وتحسين العناية بالبشرة والعناية بالشعر والصحة والجمال الشخصي.",
"Various types of vitamins, minerals, natural and nutritional food supplements that are important for public health, to raise immunity, and to treat their nutritional deficiencies in various pharmaceutical forms, which facilitates their use and maintenance.":
"أنواع مختلفة من الفيتامينات والمعادن والمكملات الغذائية الطبيعية والغذائية المهمة للصحة العامة ولرفع المناعة وعلاج نقصها الغذائي بأشكالها الصيدلانية المختلفة مما يسهل استخدامها وصيانتها.",
"BIOMAB imports the required and unique products through a series of agreements with international medical and pharmaceutical companies and factories to Turkey and the Middle East while ensuring product quality and fast delivery to our various customers.":
"تقوم شركة BIOMAB باستيراد المنتجات المطلوبة والفريدة من نوعها من خلال سلسلة من الاتفاقيات مع الشركات والمصانع الطبية والصيدلانية العالمية إلى تركيا والشرق الأوسط مع ضمان جودة المنتج وسرعة التسليم لمختلف عملائنا.",

"All types of local and international devices, tools, supplies and equipment necessary for hospitals and surgical operating rooms, as well as supplies for biological and chemical laboratories, clinics and health centers.":
"جميع أنواع الأجهزة والأدوات والمستلزمات والمعدات الطبية المحلية والعالمية اللازمة للمستشفيات وغرف العمليات الجراحية وكذلك مستلزمات العيادات والمراكز الصحية و المختبرات الطبية البيولوجية والكيميائية .",
"Various types of local and international, sterile single and multi-use consumables products used in hospitals, nursing rooms, medical laboratories and medical clinics, including international quality certificates.":
"مختلف أنواع المنتجات الاستهلاكية المحلية والعالمية المعقمة المخصصة للاستخدام لمرة واحدة او متعددة الاستخدام. والتي تمتلك شهادات الجودة العالمية و المستخدمة في المستشفيات وغرف التمريض والمختبرات الطبية والعيادات الطبية .",
"Various types of supplies, tubes, medical containers, reagents, kits, lab tests and chemical solutions necessary for biological, biochemical and medical research laboratories.":
"أنواع مختلفة من مستلزمات المخابر من الأنابيب والاوعية والكواشف وكيتات الفحوصات المخبرية وكذلك المحاليل الكيميائية اللازمة لمختبرات البحوث العلمية ومختبرات التحاليل الطبية.",
"The most effective and pure national and international branded products, which are used to increase and support people's quality of life. Natural and vegan cosmetic products that achieve maintenance and improvement of skin and hair care as well as personal health and beauty.":
"المنتجات ذات العلامات التجارية الوطنية والدولية الأكثر فعالية ونقاء، والتي تستخدم لزيادة ودعم الصحة العامة. وكذلك منتجات التجميل الطبيعية والنباتية التي تحافظ وتحسن العناية بالبشرة والشعر والصحة الشخصية والجمالية .",
"Various types of Vitamins, Minerals, Natural and Vegan Dietary Supplements that are important for public health, to raise immunity, against some biological problems and to treat nutritional deficiencies which available in various pharmaceutical forms, which facilitates their use and maintenance":
"أنواع مختلفة من الفيتامينات والمعادن والمكملات الغذائية الطبيعية والنباتية ذات الأهمية لصحة الانسان العامة و لرفع المناعة والمستخدم بعضها ضد بعض المشاكل البيولوجية والصحية وكذلك في علاج نقص التغذية وهي متوفرة بأشكال صيدلانية متعددة مما يسهل استخدامها والمداومة عليها.",
"BIOMAB's vision is to be the leading pharmaceutical and medical products provider in Turkey, the Middle East and Africa, known for its excellence and reliability in importing, marketing, promoting, marketing and distributing specialty products and nutritional formulations. The company also aims to expand its services to include more sectors and stakeholders in the pharmaceutical industry in the Middle East and diversify its offerings to include more commercial aspects, such as medical research and medical marketing research.":
"تتمثل رؤية شركة BIOMAB في أن تكون الشركة الرائدة في مجال توفير المنتجات الصيدلانية والطبية في تركيا والشرق الأوسط وأفريقيا، والمعروفة بتميزها وموثوقيتها في استيراد وتسويق وترويج وتسويق وتوزيع المنتجات المتخصصة والتركيبات الغذائية. وتهدف الشركة أيضًا إلى توسيع خدماتها لتشمل المزيد من القطاعات وأصحاب المصلحة في صناعة الأدوية في منطقة الشرق الأوسط وتنويع عروضها لتشمل المزيد من الجوانب التجارية، مثل الأبحاث الطبية وأبحاث التسويق الطبي.",
"PROFESSIONALISM":"الإحترافية",
"DIVERSIFICATION":"التنويع",
"GENUINE CARE":"الرعاية الحقيقية",
"They are our pillars of success. We prioritize professionalism for long-term partnerships with major pharmaceutical and medical companies. Diversifying our services ensures a competitive advantage in a difficult market. Paying attention to partners' interests and exceeding expectations ensures our strong leadership position in partnerships":
" هي ركائزنا للنجاح. نحن نعطي الأولوية للاحتراف للشراكات طويلة الأمد مع الشركات الدوائية والطبية الكبرى. إن تنويع خدماتنا يضمن ميزة تنافسية في السوق الصعبة. إن الاهتمام بمصالح الشركاء وتجاوز التوقعات يضمن مكانتنا الرائدة القوية في الشراكات.",
"Biomab exports medical products and supplies from Turkey and countries in which we have partnerships, with high level of guarantees and fast delivery process to our different clients such as health clinics, private hospitals, government institutions, international tenders, international NGOs and cosmetic clinics in more than 25 countries. This includes the best conditions for long-distance cold chain medicine delivery.":
"تقوم BIOMAB بتصدير المنتجات والمستلزمات الطبية من تركيا والدول التي لدينا شراكات فيها، على مستوى عال من الضمانات وسرعة عملية النقل و التسليم لعملائنا المختلفين مثل العيادات الصحية والمستشفيات الخاصة والحكومية و المؤسسات الطارحة للمناقصات الدولية والمنظمات غير الحكومية الدولية وكذلك عيادات التجميل والصحة العامة في مايزيد عن 25 دولة. وهذا يشمل أفضل الظروف الملائمة لتوصيل أدوية سلسلة التبريد لمسافات طويلة."       



},
      },
   
    },
    lng: "en",
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;


